import React from "react";

import { LinkId } from "@onehq/anton";

import {
  CampaignQueryFilterFields,
  CustomDomainListFieldsFragment,
  CustomDomainQueryFilterFields,
  FilterOperation,
  TextDefaultQueryFilterFields,
  useGetCampaignsListLazyQuery,
  useGetCustomDomainsListLazyQuery,
  useGetTextDefaultsListLazyQuery
} from "../../../generated/graphql";
import { ORGANIZATION_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS } from "../../../constants";
import { PAGE_SIZE } from "../../../utils";
import DataOverview from "../../../components/pages/DataOverview";
import { List } from "@onehq/framework";
import {
  TextDefaultTableColumns,
  textDefaultTableDataFormatter
} from "../../../components/TextDefault/TextDefaultTable";
import {
  CampaignTableColumns,
  campaignTableDataFormatter
} from "../../../components/Campaign/CampaignTable";
import AuditReportForm from "../../auditReport/auditReport";

export const DomainTableColumns = [
  {
    header: "Domain",
    accessorKey: "domain",
    disableSortBy: true
  }
];

export const DomainTableDataFormatter = (
  data?: Array<CustomDomainListFieldsFragment>
) => [
  ...(data?.map((item: CustomDomainListFieldsFragment) => {
    return { domain: item.domain };
  }) || [])
];

const campaignsTableColumns = CampaignTableColumns.filter(
  c => c.accessorKey !== "code"
);

interface OrganizationShowProps {
  id: string;
  activeLinkId: LinkId;
}

const OrganizationShow = ({ activeLinkId, id }: OrganizationShowProps) => {
  const [
    getTextDefaultsQuery,
    { data: textDefaultsData, loading: loadingTextDefaults }
  ] = useGetTextDefaultsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: TextDefaultQueryFilterFields.OrganizationId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  const [getDomainsQuery, { data: domainsData, loading: loadingDomains }] =
    useGetCustomDomainsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: CustomDomainQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      }
    });

  const [
    getCampaignsQuery,
    { data: campaignsData, loading: loadingCampaigns }
  ] = useGetCampaignsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: CampaignQueryFilterFields.OrganizationId,
          operation: FilterOperation.With,
          value: "false"
        }
      ]
    }
  });

  return (
    <>
      {activeLinkId === VIEW_LINKS.OVERVIEW && <DataOverview />}
      {activeLinkId === VIEW_LINKS.TEXT_DEFAULTS && (
        <List
          accessor="textDefaults"
          columns={TextDefaultTableColumns}
          data={textDefaultsData}
          dataFormat={textDefaultTableDataFormatter}
          loading={loadingTextDefaults}
          refetchQuery={getTextDefaultsQuery}
        />
      )}
      {activeLinkId === VIEW_LINKS.DOMAINS && (
        <List
          accessor="customDomains"
          columns={DomainTableColumns}
          data={domainsData}
          dataFormat={DomainTableDataFormatter}
          loading={loadingDomains}
          refetchQuery={getDomainsQuery}
        />
      )}
      {activeLinkId === VIEW_LINKS.UNREGISTERED_CAMPAIGNS && (
        <List
          accessor="campaigns"
          columns={campaignsTableColumns}
          data={campaignsData}
          dataFormat={campaignTableDataFormatter}
          loading={loadingCampaigns}
          refetchQuery={getCampaignsQuery}
        />
      )}
      {activeLinkId === VIEW_LINKS.AUDIT_REPORT && <AuditReportForm />}
    </>
  );
};

export default OrganizationShow;
