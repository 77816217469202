import React from "react";
import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  useGetRowsListLazyQuery,
  useGetProjectsListLazyQuery,
  RowQueryFilterFields,
  ProjectQueryFilterFields,
  FilterOperation,
  GetRowsListQueryVariables,
  GetProjectsListQueryVariables
} from "../../../generated/graphql";
import { LIST_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW } from "../../../constants/listConstants";
import { PAGE_SIZE } from "../../../utils/helperFunctions";
import DataOverview from "../../../components/pages/DataOverview";
import {
  getRowTableColumns,
  rowTableDataFormatter
} from "../../../components/Row/RowTable";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";

interface ListShowProps {
  id?: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const ListShow = ({ id, ...props }: ListShowProps) => {
  // generating rows fetch query
  const [getRowsQuery, { data: rowData, loading: rowLoading }] =
    useGetRowsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: RowQueryFilterFields.ListId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      onCompleted: response => {
        props.setTotalPages && props.setTotalPages(response?.rows?.totalCount);
      }
    });
  const filterGetRows = (variables: GetRowsListQueryVariables) =>
    getRowsQuery({ variables });

  // generating projects fetch query
  const [getProjectsQuery, { data: projectData, loading: projectLoading }] =
    useGetProjectsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: ProjectQueryFilterFields.ListId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      onCompleted: response => {
        props.setTotalPages &&
          props.setTotalPages(response?.projects?.totalCount);
      }
    });
  const filterGetProjects = (variables: GetProjectsListQueryVariables) =>
    getProjectsQuery({ variables });

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === LIST_VIEW.OVERVIEW)
    props.setTotalPages(null);

  return (
    <>
      {props.activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
      {props.activeLinkId === LIST_VIEW.ROWS && (
        <List
          accessor="rows"
          columns={getRowTableColumns()}
          data={rowData}
          dataFormat={rowTableDataFormatter}
          loading={rowLoading}
          refetchQuery={filterGetRows}
        />
      )}
      {props.activeLinkId === LIST_VIEW.PROJECTS && (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectData}
          dataFormat={projectTableDataFormatter}
          loading={projectLoading}
          refetchQuery={filterGetProjects}
        />
      )}
    </>
  );
};

export default ListShow;
