// react modules
import React, { useMemo, useState } from "react";

// third-party modules
import styled from "styled-components";
import {
  SearchField,
  MessagePreview,
  Message as MsgFormat,
  useWatchContext,
  Button,
  useSetFieldValue,
  RichTextField,
  convertFromHTMLToString
} from "@onehq/anton";

// app modules
import {
  BasePhoneFieldsFragment,
  FilterOperation,
  MediaPosition,
  PhoneQueryFilterFields,
  PhoneStatus,
  ProjectFieldsFragment,
  useGetPhonesListLazyQuery,
  useGetProjectOptOutLanguageOptionsQuery
} from "../../../generated/graphql";
import { PAGE_SIZE, formatPhoneList, formatPhoneOption } from "../../../utils";
import { baseUrl } from "../../stepper/message";
import FloatingForm from "../../../components/Form/FloatingForm";
import { theme } from "@onehq/style";

const Divider = styled.div`
  position: relative;
  right: 8px;
  width: calc(50% - 10px);

  & > *:nth-child(-n + 3) {
    width: 100%;
  }

  & > :not(:first-child) {
    margin-top: 12px;
  }
`;

const MessageContainer = styled.div`
  width: calc(50% - 10px);
`;

const CustomButton = styled(Button)`
  margin-left: ${theme.space.spacing8};
  margin-right: ${theme.space.spacing8};
`;

interface TestTextFormFieldsProps {
  managerPhones: ProjectFieldsFragment["manager"]["recipientPhones"];
  clientPhones: ProjectFieldsFragment["client"]["clientRecipientPhones"];
  fromPhones?: Partial<BasePhoneFieldsFragment>[];
  alreadySelectedPhone?: any;
  projectMessage?: string;
  onSubmit: () => void;
}

const TestTextFormFields = ({
  managerPhones,
  clientPhones,
  alreadySelectedPhone,
  projectMessage,
  fromPhones,
  onSubmit
}: TestTextFormFieldsProps) => {
  const text = useWatchContext("message");
  const mediaUrl = useWatchContext("mediaUrl");
  const mediaPosition = useWatchContext("mediaPosition");

  const setFieldValue = useSetFieldValue();

  // show floatingForm modal
  const [showAddPhone, setShowAddPhone] = useState(false);

  const [getPhones] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const { data: projectOptOutLanguageOptions } =
    useGetProjectOptOutLanguageOptionsQuery();
  const messageOptions = useMemo(
    () =>
      projectOptOutLanguageOptions?.options?.nodes?.map(node => node?.name) ||
      [],
    [projectOptOutLanguageOptions]
  );

  const defaultFromPhoneOptions = useMemo(() => {
    const uniquePhones = Array.from(new Set(fromPhones)) || [];
    return [
      {
        label: "Active Phones",
        options: formatPhoneList(
          uniquePhones.filter(p => p.phoneStatus === PhoneStatus.Active)
        )
      },
      {
        label: "Inactive Phones",
        options: uniquePhones
          .filter(p => p.phoneStatus !== PhoneStatus.Active)
          .map(p => {
            const option: any = formatPhoneOption(p);
            option.isDisabled = true;
            return option;
          })
      }
    ];
  }, [fromPhones]);

  const defaultToPhoneOptions = useMemo(() => {
    return [
      {
        label: "Project Manager - Phone Numbers",
        options: formatPhoneList(managerPhones as any[])
      },
      {
        label: "Client - Internal Phone Numbers",
        options: formatPhoneList(clientPhones as any[])
      }
    ];
  }, [managerPhones, clientPhones]);

  const messages: MsgFormat[] = useMemo(() => {
    const content: MsgFormat[] = [
      { format: "text", text: "Hey 👋", type: "received" }
    ];
    if (text) {
      content.push({
        format: "text",
        // necessary because the rich text field library works with html underneath
        text: convertFromHTMLToString(text as string) || "",
        type: "sent"
      });
    }
    if (mediaUrl) {
      const img: MsgFormat = {
        format: "attachment",
        mediaURL: baseUrl + mediaUrl,
        alt: "attachment",
        type: "sent"
      };
      const mediaBefore = mediaPosition === MediaPosition.Before;
      content.splice(mediaBefore ? 1 : text ? 2 : 1, 0, img);
    }
    return content;
  }, [text, mediaUrl, mediaPosition]);

  const reloadContent = () => {
    setFieldValue("message", projectMessage);
  };

  return (
    <>
      <Divider>
        {/* @ts-ignore */}
        <SearchField
          label="From"
          name="fromPhoneId"
          defaultOptions={defaultFromPhoneOptions}
          loadOptions={(text: string) => {
            const cleanText = text.replaceAll(/\(|\)|-|\s/g, "");
            const filtered =
              fromPhones?.filter(p => {
                return (
                  p.phoneStatus === PhoneStatus.Active &&
                  p.number?.includes(cleanText)
                );
              }) || [];
            return Promise.resolve(formatPhoneList(filtered));
          }}
          required
          showAddOption={false}
        />
        {/* @ts-ignore */}
        <SearchField
          label="To"
          name="phoneId"
          defaultOptions={defaultToPhoneOptions}
          isMulti
          loadOptions={(text: string) => {
            const cleanText = text.replaceAll(/\(|\)|-|\s/g, "");
            if (!parseInt(cleanText, 10)) return Promise.resolve([]);
            return getPhones({
              variables: {
                first: PAGE_SIZE,
                filters: [
                  {
                    field: PhoneQueryFilterFields.Number,
                    operation: FilterOperation.Like,
                    value: cleanText.replaceAll(/\(|\)|-|\s/g, "")
                  }
                ]
              }
            }).then(response => {
              return formatPhoneList(response.data?.phones?.nodes || []);
            });
          }}
          required
          onAddNew={() => setShowAddPhone(true)}
        />
        <RichTextField
          name="message"
          label="Body"
          resize="vertical"
          required
          plainText
          placeholders={messageOptions}
          helpText="You can add an OPT-OUT option typing “/” here"
          placeholderTitle={"Add OPT-OUT here"}
        />
        {alreadySelectedPhone && (
          <CustomButton
            onClick={reloadContent}
            variant="secondary"
            icon="refreshCw"
            iconPosition="leading"
          >
            Reload Content
          </CustomButton>
        )}
        <CustomButton
          onClick={onSubmit}
          variant="primary"
          icon="arrowRight"
          iconPosition="tailing"
        >
          Send Text
        </CustomButton>
      </Divider>
      <MessageContainer>
        <MessagePreview content={messages} type="sms" />
      </MessageContainer>
      <FloatingForm
        open={showAddPhone}
        onClose={() => setShowAddPhone(false)}
        variant={"Phone"}
        showOverlay
        attachNewValueToInput
      />
    </>
  );
};

export default TestTextFormFields;
