import {
  Button as DefaultButton,
  DateField,
  Fieldset,
  FormBuilder,
  ContentCard,
  Pagination
} from "@onehq/anton";
import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useGenerateCsv } from "../../components/generateCsv";
import {
  BillingReport,
  BillingReportDocument,
  useBillingReportQuery
} from "../../generated/graphql";
import BillingTable from "./billingReportTable";

const PAGE_SIZE = 15;

const Wrapper = styled.div`
  display: flex;

  & > form {
    margin: 0 -8px;
    padding: 0;
  }
`;
const Button = styled(DefaultButton)`
  align-self: end;
  margin: ${props => props.theme.space.spacing8};
  margin-right: 0;
`;
const PaginationContainer = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: center;
`;

// converts input's date (mm / dd / yyyy) to standart format (yyyy-mm-dd)
function format(str = "") {
  const parts = str.split(" / ");
  return `${parts[2]}-${parts[0]}-${parts[1]}`;
}

const BillingReportForm = () => {
  const [today] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });
  const [page, setPage] = useState(1);
  const ref = useRef<HTMLFormElement>();

  const { data, loading, refetch } = useBillingReportQuery({
    fetchPolicy: "cache-and-network",
    variables: { startDate: today, endDate: today }
  });
  const rows = useMemo(() => {
    return (
      (data?.billingReport.nodes?.filter(n => n !== null) as BillingReport[]) ||
      []
    );
  }, [data]);
  const paginatedRows = useMemo(() => {
    const start = (page - 1) * PAGE_SIZE;
    return rows.slice(start, start + PAGE_SIZE);
  }, [rows, page]);

  const { csvButtonProps, csvLink } = useGenerateCsv({
    disable: rows.length === 0,
    apolloDocument: BillingReportDocument,
    filename: "billing-report"
  });

  const onSubmit = ({ startDate, endDate }) => {
    setPage(1);
    void refetch({ startDate, endDate });
    return Promise.resolve();
  };

  const onExportClick = () => {
    const form: HTMLFormElement | undefined = ref.current?.formRef.current;
    if (form && csvButtonProps.onClick) {
      const startDate = (form[0] as HTMLInputElement)?.value;
      const endDate = (form[1] as HTMLInputElement)?.value;
      if (startDate && endDate) {
        csvButtonProps.onClick({
          startDate: format(startDate),
          endDate: format(endDate)
        });
      }
    }
  };

  return (
    <ContentCard title="Billing Report">
      <>{csvLink}</>
      <Wrapper>
        <FormBuilder
          values={{ startDate: today, endDate: today }}
          onSubmit={onSubmit}
          ref={ref}
        >
          <Fieldset>
            <DateField label="Start Date" name="startDate" required />
            <DateField label="End Date" name="endDate" required />
          </Fieldset>
        </FormBuilder>
        <Button {...csvButtonProps} onClick={onExportClick}>
          Export
        </Button>
      </Wrapper>
      <BillingTable data={paginatedRows} loading={loading} />
      <PaginationContainer>
        <Pagination
          currentPage={page}
          onPageClick={page => setPage(page)}
          totalPages={Math.ceil(rows.length / PAGE_SIZE)}
        />
      </PaginationContainer>
    </ContentCard>
  );
};

export default BillingReportForm;
