export const CAMPAIGNS_PATH = "campaigns";

export const CAMPAIGN_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  PHONES: "Phones"
};

export const CAMPAIGN_MENU_ANCHOR_EDIT_LINKS = {
  GENERAL: "General"
};
