import React from "react";

import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  GetTextsListQuery,
  useGetTextsListLazyQuery,
  TextQueryFilterFields,
  FilterOperation
} from "../../../generated/graphql";
import { PAGE_SIZE } from "../../../utils/helperFunctions";
import { PHONE_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS } from "../../../constants/phoneConstants";
import DataOverview from "../../../components/pages/DataOverview";
import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../../../components/Text/TextTable";

interface PhoneShowProps {
  id: string;
  setTotalPages?: Function;
  activeLinkId: LinkId;
}

const PhoneShow = ({ id, ...props }: PhoneShowProps) => {
  const QueryVariables = {
    first: PAGE_SIZE
  };

  const [getTextsQuery, { data: textsData, loading: loadingTexts }] =
    useGetTextsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        ...QueryVariables
      },
      onCompleted: (response: GetTextsListQuery) => {
        props.setTotalPages && props.setTotalPages(response.texts.totalCount);
      }
    });

  const formattedTexts = useTextTableDataFormatter(
    // @ts-ignore
    textsData?.texts?.nodes || []
  );

  const filterGetTextQuery = (options?: Object, extraFilters?: Object[]) => {
    const extra = extraFilters || [];
    return getTextsQuery({
      ...options,
      variables: {
        ...QueryVariables,
        // @ts-ignore
        ...options.variables,
        filters: [...extra]
      }
    });
  };
  return (
    <>
      {props.activeLinkId === VIEW_LINKS.OVERVIEW && <DataOverview />}
      {props.activeLinkId === VIEW_LINKS.SENT_TEXTS && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTextQuery(options, [
              {
                field: TextQueryFilterFields.FromPhoneId,
                operation: FilterOperation.Equal,
                value: id
              }
            ] as Object[])
          }
        />
      )}
      {props.activeLinkId === VIEW_LINKS.RECEIVED_TEXTS && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTextQuery(options, [
              {
                field: TextQueryFilterFields.ToPhoneId,
                operation: FilterOperation.Equal,
                value: id
              }
            ] as Object[])
          }
        />
      )}
    </>
  );
};

export default PhoneShow;
