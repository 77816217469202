export const PROJECTS_PATH = "projects";

export const PROJECT_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  TEAMS: "Teams",
  TEXTERS: "Texters",
  TEXTS: "Texts",
  TEST_TEXTS: "Test Texts",
  SEED_TEXTS: "Seed Texts",
  TEXTS_INSIGHTS: "Insights",
  DUPLICATE_PHONES: "Duplicate Phones",
  INVALID_PHONES: "Invalid Phones",
  OPT_OUT_PHONES: "Opt Out Phones"
};

export const PROJECT_MENU_ANCHOR_EDIT_LINKS = {
  GENERAL: "General"
};

export const PROJECT_VIEW_LINKS = [
  {
    id: PROJECT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: PROJECT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: PROJECT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEAMS,
    text: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEAMS,
    name: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEAMS,
    path: "/teams"
  },
  {
    id: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTERS,
    text: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTERS,
    name: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTERS,
    path: "/texters"
  },
  {
    id: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    text: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    name: PROJECT_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    path: "/test-texts"
  }
];
// not used yet
export const PROJECT_EDIT_LINKS = [
  {
    id: PROJECT_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    text: PROJECT_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    name: PROJECT_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    path: "/"
  }
];
