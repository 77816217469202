import React, { useEffect } from "react";
import {
  Footer,
  FooterText,
  Header,
  ImageWrapper,
  PageWrapper,
  ResponsiveImage,
  StyledH2,
  SubTitleHeader,
  Text,
  TextContainer
} from "./OptInStyles";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClientBySlugQuery } from "../../../generated/graphql";
import RGO_LOGO from "../../../assets/opts/logos/readygop_logo.png";
const OptInTerms = () => {
  const navigateTo = useNavigate();
  const { slug } = useParams();
  const { data } = useGetClientBySlugQuery({
    variables: { slug: slug || "" },
    onCompleted: data => {
      const client = data?.clients?.nodes?.[0];
      if (!client) {
        navigateTo("/");
      }
    }
  });
  const client = data?.clients?.nodes?.[0];

  const mediaUrl = client?.mediaUrl
    ? (process.env.REACT_APP_BACKEND_BASE_END_POINT || "") + client?.mediaUrl
    : "";

  const clientName = client?.name;
  const organizationName = client?.name;
  const organizationEmail = client?.email;
  const color = client?.color || "transparent";
  const slogan = client?.slogan;

  useEffect(() => {
    const tabTitle = (clientName || "") + " - Terms";
    let link: HTMLLinkElement | null =
      document.querySelector('link[rel="icon"]');
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = mediaUrl || RGO_LOGO;
    document.title = tabTitle;
  }, [clientName, mediaUrl]);

  return (
    <PageWrapper>
      <Header color={color}>Terms and Conditions</Header>
      <ImageWrapper>
        <ResponsiveImage src={mediaUrl || ""} />
      </ImageWrapper>
      <SubTitleHeader color={color}>{slogan}</SubTitleHeader>
      <TextContainer>
        <Text>Last updated: May 20, 2024</Text>
        <StyledH2 color={color}>1. Introduction</StyledH2>
        <Text>
          These Terms and Conditions (&quot;Terms&quot;) govern your access to
          and use of the products, services, websites and applications
          (collectively referred to as the &quot;Services&quot;) provided by{" "}
          {organizationName}
          .com and its affiliated companies (collectively referred to as &quot;
          {organizationName}&quot; or &quot;we&quot;). Please read these Terms
          carefully before using any of the Services. By accessing or using any
          Service, you agree to be bound by these Terms. If you do not agree to
          all the Terms, then you may not access or use the Services.
        </Text>
        <StyledH2 color={color}>2. Eligibility and Acceptance</StyledH2>
        <Text>
          You must be at least 18 years old to use the Services. By accepting
          these Terms, you represent and warrant that: (i) you are at least 18
          years old; (ii) you have not previously been suspended or removed from
          the Services; and (iii) your registration and your use of the Services
          is in compliance with all applicable laws and regulations.
        </Text>
        <StyledH2 color={color}>
          3. Services and {organizationName} Policies
        </StyledH2>
        <Text>
          {organizationName} may make certain Services available to individuals
          who register for a {organizationName} account (&quot;Account&quot;).
          You must provide accurate and complete information and keep your
          Account information updated.
          {organizationName} provides its Services in accordance with its
          policies as published on its website or within the Services. You agree
          to adhere to all applicable {organizationName} policies when using the
          Services. {organizationName} may revise its policies from time to time
          by posting updated versions on its website and within the Services.{" "}
          {organizationName} may contact you via phone call, SMS, email or other
          means using the contact information associated with your Account in
          order to provide notices regarding the Services, billing/payment
          issues, promotions or other transactional information.
        </Text>
        <StyledH2 color={color}>4. User Content</StyledH2>
        <Text>
          Some Services may allow you to upload content to your Account
          including photos, videos, audio files, comments or other materials
          (&quot;User Content&quot;). You retain ownership of your User Content.
          You grant
          {organizationName} a worldwide, non-exclusive, royalty-free,
          sublicensable and transferable license to use, reproduce, distribute,
          prepare derivative works of, display, and perform your User Content in
          connection with the Services. You are solely responsible for your User
          Content and represent and warrant that you have all rights necessary
          to grant {organizationName} the license rights in this section without
          infringing or violating third party rights. You agree to abide by our
          Content Policy when using or submitting User Content.{" "}
          {organizationName} reserves the right to remove User Content from the
          Services at its discretion.
        </Text>
        <StyledH2 color={color}>5. Fees and Payment</StyledH2>
        <Text>
          Some Services require payment of fees. You agree to pay all applicable
          fees based on {organizationName}&quot;s payment terms.{" "}
          {organizationName} may change its fees and payment policies for the
          Services by posting updated terms on its websites or within the
          applicable Services.
        </Text>
        <StyledH2 color={color}>6. Use Restrictions and Termination</StyledH2>
        <Text>
          You agree not to misuse the Services or assist anyone else in doing
          so. For example, you will not: Copy, modify, host, stream, sublicense,
          or resell any part of the Services; Access or use any Service in
          violation of applicable laws; Upload viruses, spyware or other
          malicious code; Violate the rights of others; Send spam or other
          unsolicited communications; Interfere with the proper operation of the
          Services; or Access or use the Services for any illegal or
          unauthorized purpose. If
          {organizationName} determines, in its sole discretion, that you have
          violated these Terms then we may temporarily or permanently ban you
          from the Services without notice. We may also block, restrict,
          disable, suspend or terminate your access to the Services at any time
          in our sole discretion.
        </Text>
        <StyledH2 color={color}>
          7. Disclaimers and Limitations of Liability
        </StyledH2>
        <Text>
          The Services and all information, products and other content included
          in or accessible from the Services are provided on an &quot;as
          is&quot; and &quot;as available&quot; basis. To the fullest extent
          permitted under applicable law, {organizationName} disclaims all
          representations and warranties of any kind, whether express, implied
          or statutory, regarding the Services and your use thereof.{" "}
          {organizationName} does not warrant that the Services will be
          uninterrupted, timely, secure, error-free or free from viruses or
          other harmful components. {organizationName} will not be liable for
          any damages arising out of your use of the Services. This includes
          direct, indirect, incidental, punitive and consequential damages.
        </Text>
        <StyledH2 color={color}>8. Indemnity</StyledH2>
        <Text>
          You agree to fully indemnify, defend and hold harmless{" "}
          {organizationName} and its officers, directors, employees and agents,
          from and against any and all claims, damages, obligations, losses,
          liabilities, costs and expenses arising from: (a) your access to or
          use of the Services; (b) your User Content; (c) your violation of
          these Terms; or (d) your violation of any third party rights.
        </Text>
        <StyledH2 color={color}>9. General Provisions</StyledH2>
        <Text>
          These Terms constitute the entire agreement between you and{" "}
          {organizationName}
          relating to their subject matter and supersede any prior version of
          these Terms. These Terms do not create any third party beneficiary
          rights or any agency, partnership or joint venture. {organizationName}
          &quot;s failure to enforce a provision is not a waiver of its right to
          do so later. If any provision of these Terms is found unenforceable
          then that provision will be severed from these Terms. The remaining
          Terms will remain in full effect. {organizationName} may assign any of
          its rights and obligations under these Terms. These Terms are governed
          by the laws of the State of Delaware without regard to conflict of law
          principles. You agree that any dispute arising out of or relating to
          these Terms or the Services will be subject to the exclusive
          jurisdiction and venue of the state and federal courts located in
          Delaware.
        </Text>
        <StyledH2 color={color}>10. Contact Us</StyledH2>
        <Text>
          If you have any questions about these Terms or the Services, please
          contact us at: <a href={""}>{organizationEmail}</a>
        </Text>
      </TextContainer>
      <Footer color={color}>
        <FooterText>© 2024 All rights reserved.</FooterText>
        <FooterText>{client?.email}</FooterText>
      </Footer>
    </PageWrapper>
  );
};

export default OptInTerms;
