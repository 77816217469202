import React from "react";

import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  ClientQueryFilterFields,
  FilterOperation,
  GetClientsListQuery,
  GetProjectsListQuery,
  ProjectQueryFilterFields,
  useGetClientsListLazyQuery,
  useGetProjectsListLazyQuery
} from "../../../generated/graphql";
import { CLIENT_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS } from "../../../constants/clientConstants";
import DataOverview from "../../../components/pages/DataOverview";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";
import {
  ClientTableColumns,
  clientTableDataFormatter
} from "../../../components/Client/ClientTable";

interface ClientViewProps {
  id: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const ClientShow = ({ id, ...props }: ClientViewProps) => {
  const [getAgenciesQuery, { data: clientsData, loading: loadingClients }] =
    useGetClientsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        filters: [
          {
            field: ClientQueryFilterFields.AgencyId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      onCompleted: (response: GetClientsListQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props.setTotalPages && props.setTotalPages(response.clients.totalCount);
      }
    });

  const [getProjectsQuery, { data: projectsData, loading: loadingProjects }] =
    useGetProjectsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        filters: [
          {
            field: ProjectQueryFilterFields.ClientId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      onCompleted: (response: GetProjectsListQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props.setTotalPages &&
          props.setTotalPages(response.projects.totalCount);
      }
    });

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === VIEW_LINKS.OVERVIEW)
    props.setTotalPages(null);

  switch (props.activeLinkId) {
    case VIEW_LINKS.OVERVIEW:
      return <DataOverview />;
    case VIEW_LINKS.AGENCIES:
      return (
        <List
          accessor="clients"
          columns={ClientTableColumns}
          data={clientsData}
          dataFormat={clientTableDataFormatter}
          loading={loadingClients}
          refetchQuery={getAgenciesQuery}
        />
      );
    case VIEW_LINKS.PROJECTS:
      return (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectsData}
          dataFormat={projectTableDataFormatter}
          loading={loadingProjects}
          refetchQuery={getProjectsQuery}
        />
      );
    default:
      return <></>;
  }
};

export default ClientShow;
