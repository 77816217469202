/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from "react";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";
import _ from "lodash";

import ListPage from "../../components/pages/ListPage";
import ListAnchor from "./ListAnchor";
import {
  LISTS_PATH,
  LIST_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS
} from "../../constants";
import sections, { listFormOmitValues as formOmitValues } from "./sections";
import {
  BaseListFieldsFragment,
  useCreateListMutation,
  useGetListQuery,
  useNewListQuery,
  useUpdateListMutation
} from "../../generated/graphql";
import DataOverview from "../../components/pages/DataOverview";
import ListShow from "./ListShow";

const ListsRoute = new ResourceRoute({
  name: "List",
  path: LISTS_PATH,
  IndexComponent: () => <ListPage variant="Lists" />, // list of lists
  AnchorComponent: ListAnchor,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "List",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Projects",
      path: "projects",
      baseRoute: "List",
      IndexComponent: ({ id }: { id: string }) => (
        <ListShow id={id} activeLinkId={VIEW_LINKS.PROJECTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Rows",
      path: "rows",
      baseRoute: "List",
      IndexComponent: ({ id }: { id: string }) => (
        <ListShow id={id} activeLinkId={VIEW_LINKS.ROWS} />
      )
    })
  ],
  formProps: {
    scrollable: true,
    autosave: true,
    sections,
    // @ts-ignore
    hydrate: [useNewListQuery, "newList"],
    // @ts-ignore
    query: [useGetListQuery, "list"],
    // @ts-ignore
    update: [useUpdateListMutation, "updateList"],
    // @ts-ignore
    create: [useCreateListMutation, "createList"],
    normalize: (list: BaseListFieldsFragment) => {
      if (!list) return list;
      return {
        ..._.omit(list, formOmitValues)
      };
    }
  }
});

export { ListsRoute };
