/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useState, useMemo, useEffect } from "react";

// third-party modules
import {
  Button,
  TextareaField,
  SearchField,
  Fieldset,
  useWatchContext,
  useGetValue,
  useSetFieldValue
} from "@onehq/anton";
import { theme } from "@onehq/style";
import styled from "styled-components";
import { ApolloError } from "@apollo/client";

// app modules
import {
  useGetProjectTextersListLazyQuery,
  useGetPhonesListQuery,
  TextInput,
  FilterOperation,
  ProjectTexterQueryFilterFields,
  useCustomCurrentUserQuery,
  useGetPhonesListLazyQuery,
  useGetProjectsListLazyQuery,
  PhoneQueryFilterFields,
  ProjectQueryFilterFields,
  PhoneStatusGroup,
  RestrictionOperation,
  TextStatus
} from "../../../generated/graphql";
import { GenericFormProps, SelectFieldOptionType } from "../../../types";
import { DiscardFormModal } from "../../../contexts/DiscardFormModal";
import {
  formatProjectList,
  formatTexterList,
  formatPhoneList
} from "../../../utils/options";
import { DEFAULT_LIMIT } from "../../../constants";
import ButtonGroup from "../../../components/Form/ButtonGroup";
import { isAllowed } from "../../../utils";

export interface ProjectFormFieldsProps extends GenericFormProps<TextInput> {
  resourceToFill?: {
    type: String;
    id: String;
  };
  isEdit?: boolean;
  onSubmit: () => void;
  onAddNewPhone?: (value: string) => void;
  setSaveAndSend?: React.Dispatch<React.SetStateAction<boolean>>;
  searchFieldInputs?: {
    [key: string]: any;
  };
  submitForm?: boolean;
  setSubmitForm?: Function;
}

interface DropdownOption {
  label: string | React.ReactNode | undefined;
  value: string | undefined;
}

const { radius } = theme.border;

const StyledTextareaField = styled(TextareaField)`
  border-radius: ${radius.md};
  height: 100%;
  outline: none;
  resize: none;
  width: 100%;
`;

interface PhoneSelectOption {
  label: string;
  options: SelectFieldOptionType[];
}

const TextFormFields = (props: ProjectFormFieldsProps) => {
  // hooks
  // this is for get access to the projectId field value
  const projectIdWatcher = useWatchContext("projectId");
  // and to get the value of it
  const getValue = useGetValue();
  // we have to set texterId to null when we select any projectId
  const setFieldValue = useSetFieldValue();

  // setting consts from props
  const isEdit = props.isEdit;
  const onSubmit = props.onSubmit;

  // useState
  // state variables for dropdown options
  const [defaultProjectOptions, setDefaultProjectOptions] = useState<
    SelectFieldOptionType[]
  >([]);
  const [texterOptions, setTexterOptions] = useState<DropdownOption[]>([]);
  const [defaultFromPhoneOptions, setDefaultFromPhoneOptions] = useState<
    PhoneSelectOption[]
  >([]);
  const [defaultToPhoneOptions, setDefaultToPhoneOptions] = useState<
    PhoneSelectOption[]
  >([]);
  // first render flag, this is for avoid setting texterId on first render
  const [firstRender, setFirstRender] = useState<boolean>(true);

  // queries
  // queries for dropdown options
  const [refetchTexters] = useGetProjectTextersListLazyQuery({
    fetchPolicy: "no-cache"
  });
  const [projectsQuery] = useGetProjectsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [phonesQuery] = useGetPhonesListLazyQuery({
    fetchPolicy: "no-cache"
  });
  const { data: fromPhonesList } = useGetPhonesListQuery({
    variables: {
      statusGroupFilter: PhoneStatusGroup.FromPhone,
      limit: DEFAULT_LIMIT
    }
  });
  const { data: toPhonesList } = useGetPhonesListQuery({
    variables: {
      statusGroupFilter: PhoneStatusGroup.ToPhone,
      limit: DEFAULT_LIMIT
    }
  });

  // gets current user data, used to add it in the texterOptionList
  const { data: currentUser, loading: loadingCurrentUser } =
    useCustomCurrentUserQuery();

  // use effects
  useEffect(() => {
    if (fromPhonesList) {
      setDefaultFromPhoneOptions([
        {
          label: "From phone",
          options: formatPhoneList(fromPhonesList.phones?.nodes || [])
        }
      ]);
    }
  }, [fromPhonesList]);

  useEffect(() => {
    if (toPhonesList) {
      setDefaultToPhoneOptions([
        {
          label: "To phone",
          options: formatPhoneList(toPhonesList.phones?.nodes || [])
        }
      ]);
    }
  }, [toPhonesList]);

  // setting texter options (refetch texters everytime form value "projectId" is changed)
  useEffect(() => {
    // if currentUser data is available, attach it into texter options
    const currentUserOption: DropdownOption[] = [];
    if (!loadingCurrentUser && !!currentUser) {
      currentUserOption.push({
        label: `${currentUser.currentUser.name} (You)`,
        value: currentUser.currentUser.id
      });
    }
    // getting current projectId
    const projectIdValue = getValue("projectId")?.value;
    // if there is selected projectId, get texters from that project
    if (projectIdValue) {
      // setting queryProps to get texters by current projectId
      const queryProps = {
        variables: {
          filters: {
            field: ProjectTexterQueryFilterFields.ProjectId,
            operation: FilterOperation.Equal,
            value: projectIdValue
          }
        }
      };
      // getting texters from selected project
      refetchTexters(queryProps).then(
        response => {
          const textersListAux: DropdownOption[] =
            response.data?.projectTexters?.nodes
              // dont add current user twice
              ?.filter(
                pt => !currentUser || pt?.userId !== currentUser.currentUser.id
              )
              .map(pt => ({
                label: pt?.user.name,
                value: pt?.userId
              })) || [];
          // add current user (if any) into texter options
          textersListAux.unshift(...currentUserOption);
          setTexterOptions(textersListAux);
          // reseting texterId to null, if this isnt the first render (unselect current texter id whenever projectId is changed)
          if (firstRender) setFirstRender(false);
          else setFieldValue("texterId", null);
        },
        (error: ApolloError) => {
          console.log(error);
        }
      );
    } else {
      // clear out texter options and leave just current user if there is not projectId
      setTexterOptions(currentUserOption);
    }
  }, [projectIdWatcher?.value, loadingCurrentUser]);

  // load the default options for the phone fields
  useEffect(() => {
    projectsQuery({
      variables: {
        limit: DEFAULT_LIMIT
      }
    })
      .then(response => {
        const projectsData = response.data?.projects?.nodes || [];
        setDefaultProjectOptions(formatProjectList(projectsData));
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (props.submitForm && props.setSubmitForm && props.searchFieldInputs) {
      setFieldValue("fromPhoneId", props.searchFieldInputs.fromPhoneId);
      isEdit && props.onSubmit();
      props.setSubmitForm(false);
    }
  }, [props.searchFieldInputs?.fromPhoneId]);

  useEffect(() => {
    if (props.submitForm && props.setSubmitForm && props.searchFieldInputs) {
      setFieldValue("toPhoneId", props.searchFieldInputs.toPhoneId);
      isEdit && props.onSubmit();
      props.setSubmitForm(false);
    }
  }, [props.searchFieldInputs?.toPhoneId]);

  // other constants or variables
  const canCreatePhone = isAllowed(
    currentUser,
    "Phone",
    RestrictionOperation.Create
  );

  const textStatus = props.values.textStatus;
  const isEditable = useMemo(() => {
    return (
      !isEdit ||
      textStatus === undefined ||
      [TextStatus.Loading, TextStatus.Ready, TextStatus.Sending].includes(
        textStatus ?? TextStatus.Loading
      )
    );
  }, [textStatus, isEdit]);

  return (
    <>
      <Fieldset>
        {/* @ts-ignore */}
        <SearchField
          label="Project"
          name="projectId"
          defaultOptions={defaultProjectOptions}
          disabled={!isEditable}
          loadOptions={(text: string) =>
            projectsQuery({
              variables: {
                filters: [
                  {
                    field: ProjectQueryFilterFields.Name,
                    operation: FilterOperation.Like,
                    value: text
                  }
                ]
              }
            }).then(response =>
              formatProjectList(response.data?.projects?.nodes || [])
            )
          }
        />
        {/* @ts-ignore */}
        <SearchField
          label="Texter"
          name="texterId"
          defaultOptions={texterOptions}
          disabled={!isEditable}
          loadOptions={(text: string) =>
            refetchTexters({
              variables: {
                filters: [
                  {
                    field: ProjectTexterQueryFilterFields.UserId,
                    operation: FilterOperation.Like,
                    value: text
                  }
                ]
              }
            }).then(response =>
              formatTexterList(response.data?.projectTexters?.nodes || [])
            )
          }
        />
        {/* @ts-ignore */}
        <SearchField
          label="To Phone"
          name="toPhoneId"
          placeholder="To Phone options"
          defaultOptions={defaultToPhoneOptions}
          disabled={!isEditable}
          loadOptions={(text: string) =>
            phonesQuery({
              variables: {
                filters: [
                  {
                    field: PhoneQueryFilterFields.Number,
                    operation: FilterOperation.Like,
                    value: text.replaceAll(/\(|\)|-/g, "")
                  }
                ],
                statusGroupFilter: PhoneStatusGroup.ToPhone
              }
            }).then(response =>
              formatPhoneList(response.data?.phones?.nodes || [])
            )
          }
          onAddNew={
            canCreatePhone
              ? () => props.onAddNewPhone && props.onAddNewPhone("toPhoneId")
              : undefined
          }
        />
        {/* @ts-ignore */}
        <SearchField
          label="From Phone"
          name="fromPhoneId"
          placeholder="From Phone options"
          defaultOptions={defaultFromPhoneOptions}
          disabled={!isEditable}
          loadOptions={(text: string) =>
            phonesQuery({
              variables: {
                filters: [
                  {
                    field: PhoneQueryFilterFields.Number,
                    operation: FilterOperation.Like,
                    value: text.replaceAll(/\(|\)|-/g, "")
                  }
                ],
                statusGroupFilter: PhoneStatusGroup.FromPhone
              }
            }).then(response =>
              formatPhoneList(response.data?.phones?.nodes || [])
            )
          }
          onAddNew={
            canCreatePhone
              ? () => props.onAddNewPhone && props.onAddNewPhone("fromPhoneId")
              : undefined
          }
        />
        <StyledTextareaField label="Body" name="body" disabled={!isEditable} />
      </Fieldset>

      {!isEdit && (
        <ButtonGroup>
          <DiscardFormModal resource="Text">
            <Button variant={"secondary"}> Discard </Button>
          </DiscardFormModal>
          <Button
            onClick={() => {
              props.setSaveAndSend && props.setSaveAndSend(true);
            }}
          >
            Save and send
          </Button>
          <Button
            onClick={() => {
              props.setSaveAndSend && props.setSaveAndSend(false);
              onSubmit();
            }}
          >
            Save
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default TextFormFields;
