/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { badgeCell, CellDataType, Table } from "@onehq/anton";
import { Link } from "@onehq/framework";
import { PhoneListFieldsFragment } from "../../generated/graphql";
import {
  addSpacesBetweenWords,
  formatPhone,
  phoneStatusBadgeColor
} from "../../utils";
import { BaseTableProps } from "../../types";
import { PHONES_PATH } from "../../constants";

export type PhoneTableProps = BaseTableProps<PhoneListFieldsFragment>;

export const PhoneTableColumns = [
  {
    header: "Number",
    accessorKey: "number"
  },
  {
    header: "Owner",
    accessorKey: "owner",
    disableSortBy: true
  },
  {
    header: "Type",
    accessorKey: "phoneType",
    disableSortBy: true
  },
  {
    header: "Status",
    accessorKey: "phoneStatus",
    disableSortBy: true
  },
  {
    header: "Class",
    accessorKey: "phoneClass",
    disableSortBy: true
  },
  {
    header: "State",
    accessorKey: "state.abbreviation",
    disableSortBy: false
  },
  {
    header: "Do not contacct",
    accessorKey: "doNotContact",
    disableSortBy: false
  },
  {
    header: "Description",
    accessorKey: "description",
    dataType: "longText" as CellDataType,
    disableSortBy: true
  }
];

export const phoneTableDataFormatter = (
  data?: Array<PhoneListFieldsFragment>
) => [
  ...(data?.map((item: PhoneListFieldsFragment) => {
    const { id, number, user, client, phoneType, phoneStatus, description } =
      item;
    const owner = user ? user : client;
    return {
      number: (
        <Link to={`/${PHONES_PATH}/${id}/overview`}>{formatPhone(number)}</Link>
      ),
      phoneStatus: badgeCell({
        value: addSpacesBetweenWords(phoneStatus),
        color: phoneStatusBadgeColor(phoneStatus)
      }),
      owner: (
        <Link to={user ? `/users/${owner?.id}` : `/clients/${owner?.id}`}>
          {owner?.name}
        </Link>
      ),
      description,
      phoneType: addSpacesBetweenWords(phoneType)
    };
  }) || [])
];

const PhoneTable = ({ data = [], ...props }: PhoneTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={PhoneTableColumns}
      data={phoneTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default PhoneTable;
