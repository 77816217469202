/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ProjectListFieldsFragment } from "../../generated/graphql";
import { badgeCell, BadgeTheme, CellDataType, Table } from "@onehq/anton";
import {
  addSpacesBetweenWords,
  formatDate,
  projectStatusBadgeColor
} from "../../utils";
import { Link } from "@onehq/framework";
import { BaseTableProps } from "../../types";
import { PROJECTS_PATH } from "../../constants";

export type ProjectTableProps = BaseTableProps<ProjectListFieldsFragment>;

export const ProjectTableColumns = [
  {
    header: "Name",
    accessorKey: "name"
  },
  {
    header: "Message",
    accessorKey: "message",
    dataType: "longText" as CellDataType,
    columnMaxWidth: 200,
    tooltipMaxLength: 60
  },
  {
    header: "Start At",
    accessorKey: "startAt"
  },
  {
    header: "End At",
    accessorKey: "endAt"
  },
  {
    header: "Type",
    accessorKey: "projectTypeId",
    disableSortBy: true
  },
  {
    header: "Status",
    accessorKey: "projectStatusId",
    disableSortBy: true
  }
  // {
  //   Header: "Active Texts",
  //   accessor: "activeTexts",
  //   type: "number" as CellType,
  //   disableSortBy: true
  // },
  // {
  //   Header: "Received Texts",
  //   accessor: "receivedTexts",
  //   type: "number" as CellType,
  //   disableSortBy: true
  // },
  // {
  //   Header: "Ready Texts",
  //   accessor: "readyTexts",
  //   type: "number" as CellType,
  //   disableSortBy: true
  // },
  // {
  //   Header: "Failed Texts",
  //   accessor: "failedTexts",
  //   type: "number" as CellType,
  //   disableSortBy: true
  // },
  // {
  //   Header: "Percent Done",
  //   accessor: "percentDone",
  //   type: "number" as CellType,
  //   disableSortBy: true
  // }
];

export const projectTableDataFormatter = (
  data?: Array<ProjectListFieldsFragment>
) => [
  ...(data?.map((item: ProjectListFieldsFragment) => {
    const {
      // activeTextsCount: activeTexts,
      // receivedTextsCount: receivedTexts,
      // failedTextsCount: failedTexts,
      // readyTextsCount: readyTexts,
      // percentDone,
      message,
      startAt,
      endAt,
      name,
      projectStatus,
      projectType
    } = item;

    return {
      name: <Link to={`/${PROJECTS_PATH}/${item.id}/overview`}>{name}</Link>,
      message,
      startAt: formatDate(startAt),
      endAt: formatDate(endAt),
      projectStatusId: badgeCell({
        value: addSpacesBetweenWords(projectStatus),
        color: projectStatusBadgeColor(projectStatus) as BadgeTheme
      }),
      projectTypeId: addSpacesBetweenWords(projectType)
      // activeTexts: formatNumber(activeTexts),
      // readyTexts: formatNumber(readyTexts),
      // // @ts-ignore
      // percentDone: `${roundingNumber(percentDone)}%`,
      // receivedTexts: formatNumber(receivedTexts),
      // failedTexts: formatNumber(failedTexts)
    };
  }) || [])
];

const ProjectTable = ({ data = [], ...props }: ProjectTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={ProjectTableColumns}
      data={projectTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default ProjectTable;
