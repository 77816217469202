export const USERS_PATH = "users";

export const USER_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  TEXTS: "Texts",
  PROJECTS: "Projects"
};

export const USER_MENU_ANCHOR_EDIT_LINKS = {
  INFO: "Info"
};

export const USER_VIEW_LINKS = [
  {
    id: USER_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: USER_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: USER_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: USER_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    text: USER_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    name: USER_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    path: "/texts"
  },
  {
    id: USER_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    text: USER_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    name: USER_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    path: "/projects"
  }
];

// not used yet
export const USER_EDIT_LINKS = [
  {
    id: USER_MENU_ANCHOR_EDIT_LINKS.INFO,
    text: USER_MENU_ANCHOR_EDIT_LINKS.INFO,
    name: USER_MENU_ANCHOR_EDIT_LINKS.INFO,
    path: ""
  }
];
