import moment from "moment";

export const roundingNumber = function (n: number) {
  return Math.round(n * 10) / 10;
};

export const addSpacesBetweenWords = function (text: any) {
  // this works adding a space before every capital letter, so it will work for camel case strings
  return text?.replace(/[A-Z]/g, " $&").trim();
};

export const removeSpacesBetweenWords = function (text: any) {
  return text?.replace(/ +/g, "");
};

export const formatDate = function (date: any) {
  return date && moment(date as string).format("M/D/YYYY hh:mm a");
};

export const formatPhone = (phone: string) => {
  if (!phone) {
    return "";
  }

  let formattedPhone = phone.replace(/\D/g, "");
  if (formattedPhone.length > 10) {
    formattedPhone = formattedPhone.slice(-10); // last 10 numbers
  } else if (formattedPhone.length < 10) {
    formattedPhone = formattedPhone.padStart(10, "0"); // fill with 0s at the start to get 10 numbers
  }
  return `(${formattedPhone.slice(0, 3)})-${formattedPhone.slice(
    3,
    6
  )}-${formattedPhone.slice(6, 10)}`;
};

export const optionNameFromId = (optionId: string | null | undefined) => {
  return optionId?.substring(optionId.lastIndexOf(":") + 1);
};

export const groupByObjectProperty = (objectArray: any[], property: string) => {
  return objectArray.reduce((accumulator, obj) => {
    const key = obj[property];
    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    accumulator[key].push(obj);
    return accumulator;
  }, {});
};

export const formatNumber = (n: number) => {
  // formatting number with comma separated thousands, millions, etc...
  return n?.toLocaleString("en-US");
};

interface Row {
  [key: string]: any;
}
/**
 * TODO move this functionality into react-hq Table component (utils)
 * converts O={a-0:"", a-1:"", b-0:"", b-1:""} to A=[{a:"", b:""}, {a:"", b:""}]
 * @param main object that contains all values for each table cell (e.g. O object)
 * @param keys keys of the table without index (e.g. ["a", "b"])
 * @returns array of as many objects as rows in table, each's keys without the index
 */
export function tableValuesToArray(main: any, keys: string[]): Row[] {
  // get the relevant keys
  const filter = Object.keys(main as {}).filter(key => {
    return keys.some(k => key.startsWith(k));
  });
  // if we have T keys in total (a-0, a-1, b-0, ...) and K keys (a, b, ...)
  // then we can assume there are (T/K) rows, the size
  const size = keys.length ? filter.length / keys.length : 0;
  const array: Row[] = [];
  // from 0 to size will be the indexes in the table
  for (let idx = 0; idx < size; idx++) {
    const obj: Row = {};
    keys.forEach(k => (obj[k] = main[`${k}-${idx}`]));
    array.push(obj);
  }
  return array;
}

export const PAGE_SIZE = 50;
export const READYGOP_ORGS = [1, 4];
