import { DotColor } from "@onehq/anton";
import {
  ClientType,
  ClientStatus,
  GetClientsListQuery,
  TeamStatus,
  NotificationChannel
} from "../generated/graphql";
import {
  addSpacesBetweenWords,
  optionNameFromId,
  groupByObjectProperty,
  removeSpacesBetweenWords,
  formatPhone
} from "../utils/helperFunctions";
import PhoneTooltip from "../components/Phone/PhoneTooltip";
import React from "react";

export const clientTypeOptions =
  Object.keys(ClientType).map(item => ({
    label: addSpacesBetweenWords(item),
    value: item
  })) || [];

export const notificationChannelOptions =
  Object.values(NotificationChannel).map(item => ({
    label: item,
    value: item
  })) || [];

export const clientStatusOptions =
  Object.keys(ClientStatus).map(item => ({
    label: addSpacesBetweenWords(item),
    value: item
  })) || [];

export const teamStatusOptions =
  Object.keys(TeamStatus).map(item => ({
    label: addSpacesBetweenWords(item),
    value: item
  })) || [];

export const agenciesOptionsFrom =
  ((agenciesData: GetClientsListQuery | undefined) =>
    agenciesData?.clients.nodes?.map(item => ({
      label: item?.name || "",
      value: item?.id || ""
    }))) || [];

interface OptionFieldsAndColor {
  label: any;
  value: any;
  color: DotColor;
  stage?: string;
}

export const getOptionFieldsAndColor = (
  options: any,
  COLORS: DotColor[]
): OptionFieldsAndColor[] =>
  options?.options?.nodes?.map((item: any, index: number) => ({
    label: item.name,
    value: item.name,
    color: COLORS[index % COLORS.length],
    stage: item.groupId
  })) || [];

// this is for selects that need to show group names like project status
interface GroupedSelectBaseValues {
  label: string;
  value: string;
  groupId?: string | null;
}

export interface GroupedSelectOptions {
  label: string;
  options: GroupedSelectBaseValues[];
}

export const getGroupedSelectOptions =
  ((
    optionsList: {
      __typename?: "Option" | undefined;
      groupId?: string | null | undefined;
      id: string;
      optionType: string;
      name: string;
    }[],
    optionGroup: { [s: string]: unknown }
  ) => {
    const selectAux: Array<GroupedSelectOptions> = [];
    const options: Array<GroupedSelectBaseValues> = [];

    options.push(
      ...(optionsList.map(item => ({
        label: addSpacesBetweenWords(item.name),
        value: removeSpacesBetweenWords(item.name),
        groupId: optionNameFromId(item.groupId)
      })) || [])
    );

    const validGroups = options.map(option => option.groupId?.replace(" ", ""));

    const groupedList = groupByObjectProperty(options, "groupId");

    Object.entries(optionGroup).forEach(([key]) => {
      if (validGroups.includes(key)) {
        selectAux.push({
          label: key,
          options: groupedList[addSpacesBetweenWords(key)]
        });
      }
    });

    if (groupedList["undefined"]) {
      selectAux.push({
        label: "Other",
        options: groupedList["undefined"]
      });
    }

    return selectAux;
  }) || [];

export const formatPhoneOption = (phone: any) => ({
  label: phone?.description ? (
    <PhoneTooltip description={phone?.description as string}>
      {formatPhone(phone?.number as string)}
    </PhoneTooltip>
  ) : (
    formatPhone(phone?.number as string)
  ),
  value: phone?.id
});

export const formatClientOption = (client: any) => ({
  label: client?.name,
  value: client?.id,
  organizationId: client?.organizationId
});

// list entity
export const formatListOption = (list: any) => ({
  label: list?.name,
  value: list?.id
});

export const formatUserOption = (user: any) => ({
  label: user?.name,
  value: user?.id
});

export const formatTeamOption = (team: any) => ({
  label: team?.name,
  value: team?.id
});

export const formatProjectOption = (project: any) => ({
  label: project?.name,
  value: project?.id
});

export const formatTexterOption = (texter: any) => ({
  label: texter?.user?.name,
  value: texter?.id
});

export const formatCampaignOption = (campaign: any) => ({
  label: `${campaign.name} - ${campaign.brand.provider}`,
  value: campaign.id
});

export const formatBrandOption = (brand: any) => ({
  label: brand.name,
  value: brand.id
});

export const formatPhoneList = (phones: any[]) =>
  phones?.map(phone => formatPhoneOption(phone)) || [];

export const formatClientList = (clients: any[]) =>
  clients?.map(client => formatClientOption(client)) || [];

export const formatListList = (lists: any[]) =>
  lists?.map(list => formatListOption(list)) || [];

export const formatUserList = (users: any[]) =>
  users?.map(user => formatUserOption(user)) || [];

export const formatTeamList = (teams: any[]) =>
  teams?.map(team => formatTeamOption(team)) || [];

export const formatProjectList = (projects: any[]) =>
  projects?.map(project => formatProjectOption(project)) || [];

export const formatTexterList = (texters: any[]) =>
  texters?.map(texter => formatProjectOption(texter)) || [];

export const formatCampaignList = (campaign: any[]) =>
  campaign?.map(campaign => formatCampaignOption(campaign)) || [];

export const formatBrandList = (brand: any[]) =>
  brand?.map(campaign => formatBrandOption(campaign)) || [];
