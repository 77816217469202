// react modules
import React, { useMemo, useState } from "react";

// third-party modules
import {
  CellInputType,
  HiddenField,
  Table,
  useSetFieldValue,
  useGetSubmitForm,
  SearchField,
  useGetValue
} from "@onehq/anton";
import styled from "styled-components";
import { CellContext } from "@tanstack/react-table";
import { NoPaddingButton } from "../../clients/sections/index.styles";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface BrandConfigurationsFormProps {
  providerId?: string;
  allProviderEnvKeyOptions?: any[];
  brandConfigurationsArray: any[];
}

const BrandConfigurationsForm = (props: BrandConfigurationsFormProps) => {
  // hooks
  const getValue = useGetValue();
  const setValue = useSetFieldValue();
  const submitForm = useGetSubmitForm();

  // state variables
  const [tableData, setTableData] = useState<any[]>(
    props.brandConfigurationsArray
  );

  const providerName = useMemo(() => {
    return props.providerId?.replace(/([a-z])([A-Z])/g, "$1 $2");
  }, [props.providerId]);

  const providerEnvKeyOptions = useMemo(() => {
    // array of envKeys that were already used
    const usedEnvKeys = props.brandConfigurationsArray
      .filter(bc => !!bc.envKey)
      .map(bc => bc.envKey);
    return (
      props.allProviderEnvKeyOptions
        ?.filter(
          option =>
            option?.groupId === `ProviderEnvKey::::${providerName}` &&
            !usedEnvKeys.find(envKey => envKey === option.name)
        )
        ?.map(option => ({ label: option.name, value: option.name })) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allProviderEnvKeyOptions, providerName]);

  const ActionCustomCell = useMemo(
    () =>
      ({ row }: CellContext<any, any>) => {
        // get id value to know if this cell is a new brand config or a existent one
        const isEdit = !!getValue(`id-${row.index}`);
        const isDeleting = !!getValue(`destroy-${row.index}`);

        // toggle brand config attr destroy, and submits form since "setValue" doesnt trigger autosave
        // if creation row is half is not completed, it doesnt delete this row right away since
        // we want to let the user finishing the creation of the new row of cancel it (blank the new row fields)
        const toggleDestroyBrandConfig = () => {
          setValue(`destroy-${row.index}`, !isDeleting);
          !!submitForm && submitForm();
        };
        return (
          <>
            {/* This is to keep id value into the form values */}
            <HiddenField name={`id-${row.index}`} />
            {/* This is to keep destroy value into the form values */}
            <HiddenField name={`destroy-${row.index}`} />
            {/* if this brand config exists, show delete brand config action */}
            {isEdit && (
              <NoPaddingButton
                variant="inline"
                onClick={toggleDestroyBrandConfig}
              >
                {isDeleting ? "Ready to delete" : "Delete"}
              </NoPaddingButton>
            )}
          </>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const SearchEnvKeyFieldCell = useMemo(
    () =>
      ({ row }: CellContext<any, any>) => {
        // current row is edit or new brand config
        const isEdit = !!getValue(`id-${row.index}`);

        return (
          // @ts-ignore
          <SearchField
            name={`envKey-${row.index}`}
            isTableInput
            collapseLabel
            placeholder="Select an environment key"
            // filter just by text (removes )
            loadOptions={(text: string) =>
              Promise.resolve(
                providerEnvKeyOptions.filter(
                  o =>
                    o.label
                      .toLowerCase()
                      .replace(/[^0-9a-z]/gi, "")
                      .search(text.toLowerCase()) >= 0
                )
              )
            }
            disabled={isEdit}
            defaultOptions={providerEnvKeyOptions}
          />
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providerEnvKeyOptions]
  );

  const brandCofigurationTableColumns = [
    {
      id: "envKey",
      accessorKey: "envKey",
      header: "Environment Key",
      cell: SearchEnvKeyFieldCell
    },
    {
      id: "envValue",
      accessorKey: "envValue",
      header: "Environment Value",
      inputType: "textField" as CellInputType,
      disableSortBy: true,
      maxSize: 1
    },
    {
      id: "actions",
      accessorKey: "actions",
      header: "Actions",
      cell: ActionCustomCell
    }
  ];

  return (
    <TableContainer>
      <Table
        variant="data-grid"
        columns={brandCofigurationTableColumns}
        data={tableData}
        setData={setTableData}
      />
    </TableContainer>
  );
};

export default BrandConfigurationsForm;
