/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useMemo } from "react";

// third-party modules
import {
  CellContext,
  HeaderContext,
  RowSelectionState
} from "@tanstack/react-table";
import styled from "styled-components";
import { theme } from "@onehq/style";
import {
  Action,
  ActionItemProps,
  CheckboxInput,
  CellInputType,
  Icon,
  SearchField,
  Table,
  Tooltip,
  useGetSubmitForm,
  useSetFieldValue
} from "@onehq/anton";

// app modules
import {
  Campaign,
  CampaignType,
  FilterOperation,
  PhoneQueryFilterFields,
  PhoneStatus,
  ProjectAreaCodesQuery,
  useGetCampaignsListLazyQuery,
  useGetPhonesListLazyQuery,
  useSearchAndBuyNumberMutation
} from "../../../generated/graphql";
import {
  PAGE_SIZE,
  formatCampaignList,
  formatPhoneOption
} from "../../../utils";
import { Phone } from "./ProjectCampaignsForm";
import { ADD, useDispatchGrowlContext } from "@onehq/framework";

const Root = styled.div`
  padding: ${theme.space.spacing8};
  width: 100%;
  z-index: 1;
`;

const CheckboxRoot = styled.div`
  label {
    flex-direction: column;
  }
`;

export interface AreaCodeData {
  areaCode: string;
  timesUsed: number;
  campaignId?: string;
  cursor?: string;
  phoneData?: string | { id: string; campaignId: string };
  checkColumn: any;
}

interface ProjectPhonesFieldsProps {
  campaignType: CampaignType;
  refetchQuery: Function;
  clientId?: any;
  areaCodeData?: AreaCodeData[];
  setRetrievedAreaCodes: React.Dispatch<
    React.SetStateAction<
      NonNullable<ProjectAreaCodesQuery["projectAreaCodes"]["edges"]>
    >
  >;
  setAreaCodeData: React.Dispatch<React.SetStateAction<AreaCodeData[]>>;
  isCompleted?: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchText: React.Dispatch<React.SetStateAction<string | null>>;
  setAddToInputName: React.Dispatch<React.SetStateAction<string | null>>;
  prefix: string;
  allPhones: Phone[];
  allCampaigns: Campaign[];
  pendingPhones: any[];
}

const RightAlignedSearchField = styled(SearchField)`
  text-align: right;
`;

const ProjectPhonesFields = ({
  clientId,
  areaCodeData = [],
  campaignType,
  isCompleted,
  setShowDialog,
  setSearchText,
  setAddToInputName,
  prefix,
  refetchQuery,
  setRetrievedAreaCodes,
  setAreaCodeData,
  allPhones,
  allCampaigns,
  pendingPhones
}: ProjectPhonesFieldsProps) => {
  const [selectedRows, setSelectedRows] = React.useState({});

  const [getCampaigns] = useGetCampaignsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [getPhones] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [buyNumberQuery] = useSearchAndBuyNumberMutation();

  // hooks
  const submitForm = useGetSubmitForm();
  const setValue = useSetFieldValue();
  const alert = useDispatchGrowlContext();

  // buy the number and create the pc & pp
  // we manually set the created pc & pp to the table
  const buyNumber = useMemo(() => {
    return (areaCode: string, campaignId: string) => {
      if (areaCode && campaignId) {
        buyNumberQuery({ variables: { areaCode, campaignId } })
          .then(response => {
            const errors = response.data?.searchAndBuyNumber?.errors;
            if (errors) {
              alert({
                type: ADD,
                payload: {
                  title: "Error buying number",
                  message: errors,
                  variant: "error"
                }
              });
            } else {
              alert({
                type: ADD,
                payload: {
                  title: "All changes saved",
                  message: response.data?.searchAndBuyNumber?.message,
                  variant: "success"
                }
              });
            }
          })
          .catch(error => console.log(error));
      }
    };
  }, [buyNumberQuery, alert]);

  // custom cell for phones because each row can have different phone options
  // based on the campaign in that row
  const PhoneSearchFieldCell = useMemo(() => {
    return ({ column: { id }, row }: CellContext<any, any>) => {
      const isRegistered = campaignType === CampaignType.Registered;
      const campaignId: string | undefined = row.getValue<any>("campaignId");
      const phoneData = row.getValue<any>("phoneData");
      const areaCode = row.getValue<string>("areaCode");
      const phone = allPhones.find(p => p.id === phoneData?.id);
      const mismatch = phone && !phone.number?.startsWith(areaCode);

      const activeOptions = (
        isRegistered
          ? allPhones.filter(p => p.campaignId === campaignId)
          : allPhones
      ).map(phone => {
        const option = formatPhoneOption(phone);
        option.value = { id: phone.id, campaignId: phone.campaignId };
        return option;
      });
      const pendingOptions = (
        isRegistered
          ? pendingPhones.filter(p => p.campaignId === campaignId)
          : pendingPhones.filter(p => !p.campaign?.organizationId)
      ).map(phone => {
        const option = formatPhoneOption(phone) as any;
        option.value = { id: phone.id, campaignId: phone.campaignId };
        option.isDisabled = true;
        return option;
      });
      const defaultOptions = [
        {
          label: "Pending Phones",
          options: pendingOptions
        },
        {
          label: "Active Phones",
          options: activeOptions
        }
      ];
      const loadOptions = async (text: string) => {
        if (!campaignId && campaignType === CampaignType.Registered) {
          return Promise.resolve([]);
        } else {
          return getPhones({
            variables: {
              first: PAGE_SIZE,
              filters: [
                {
                  field: PhoneQueryFilterFields.CampaignId,
                  operation: FilterOperation.In,
                  arrayValues:
                    campaignType === CampaignType.Registered
                      ? [campaignId || ""]
                      : allCampaigns.map(c => c.id)
                },
                {
                  field: PhoneQueryFilterFields.Number,
                  operation: FilterOperation.Like,
                  value: text.replaceAll(/\(|\)|-|\s/g, "")
                },
                {
                  field: PhoneQueryFilterFields.PhoneStatusId,
                  operation: FilterOperation.Equal,
                  value: `PhoneStatus::::${PhoneStatus.Active}`
                }
              ]
            }
          }).then(response => {
            const phones = response.data?.phones?.nodes || [];
            return phones.map(phone => {
              const option = formatPhoneOption(phone);
              option.value = { id: phone?.id, campaignId: phone?.campaignId };
              return option;
            });
          });
        }
      };
      return (
        <>
          {/* @ts-ignore */}
          <RightAlignedSearchField
            name={`${id}-${row.index}`}
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            formatOptionLabel={(option, { context }) => {
              return context === "menu" ? (
                option.label
              ) : (
                <>
                  {mismatch && (
                    <Tooltip
                      content="Mismatch Code"
                      placement="top"
                      delay={0}
                      color="neutral"
                    >
                      <div
                        style={{
                          display: "inline-block",
                          margin: 1,
                          marginRight: 8
                        }}
                      >
                        <Icon
                          name="notEqual"
                          color="neutral75"
                          width={12}
                          strokeWidth={2}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {option.label}
                </>
              );
            }}
            onAddNew={
              campaignId && isRegistered
                ? () => buyNumber(areaCode, campaignId)
                : undefined
            }
            addNewLabel="Buy Number"
            placeholder="Search an option"
            collapseLabel
            isTableInput
            disabled={isCompleted}
            // set selected phone in the table data
            onChange={(val: any) => {
              setAreaCodeData(areaCodeData => {
                const updatedAreaCodeData = [...areaCodeData];
                updatedAreaCodeData[row.index] = {
                  ...updatedAreaCodeData[row.index],
                  checkColumn: undefined,
                  [id]: val?.value || val
                };
                return updatedAreaCodeData;
              });
            }}
            isRightAligned
          />
        </>
      );
    };
  }, [
    allPhones,
    allCampaigns,
    pendingPhones,
    campaignType,
    isCompleted,
    getPhones,
    buyNumber,
    setAreaCodeData
  ]);

  // custom cell for campaigns because we need to get the current selectField name
  // for the floatingForm (setAddToInputName)
  const CampaignSearchFieldCell = useMemo(() => {
    return ({ column: { id }, row }: CellContext<any, any>) => (
      /* @ts-ignore */
      <SearchField
        name={`${id}-${row.index}`}
        defaultOptions={formatCampaignList(allCampaigns)}
        loadOptions={text => {
          const filter = allCampaigns.filter(c => c.name?.includes(text));
          return Promise.resolve(formatCampaignList(filter));
        }}
        onAddNew={(val: string) => {
          setSearchText(val);
          setAddToInputName(`${prefix}.${id}-${row.index}`);
          setShowDialog(true);
        }}
        placeholder="Search a campaign"
        collapseLabel
        isTableInput
        // set selected campaign in the table data
        onChange={(val: any) => {
          setValue(`phoneData-${row.index}`, null, { absolutePath: false });
          setAreaCodeData(areaCodeData => {
            const updatedAreaCodeData = [...areaCodeData];
            updatedAreaCodeData[row.index] = {
              ...updatedAreaCodeData[row.index],
              [id]: val?.value || val
            };
            return updatedAreaCodeData;
          });
        }}
        disabled={isCompleted}
      />
    );
  }, [allCampaigns, clientId, getCampaigns]);

  // columns of the table
  const columns = useMemo(() => {
    return [
      {
        accessorKey: "checkColumn",
        id: "Check Column",
        size: 20,
        header: ({ table }: HeaderContext<any, unknown>) => {
          const emptyPhoneNumberRows: RowSelectionState = table.options.data
            .map((d, index) => ({
              ...d,
              index
            }))
            .filter(f => !f.phoneData)
            .reduce((acc, item) => {
              acc[item.index] = true;
              return acc;
            }, {});

          const selectedRowIndex = Object.keys(selectedRows).map(Number);

          const isRegistered = campaignType === CampaignType.Registered;
          const activeOptions = (
            isRegistered ? allPhones.filter(p => p.campaignId) : allPhones
          ).map(phone => {
            const option = formatPhoneOption(phone);
            option.value = { id: phone.id, campaignId: phone.campaignId };
            return option;
          });
          const activeNumberActions: ActionItemProps[] = activeOptions.map(
            aN => ({
              name: aN.label as string,
              onClick: () => {
                selectedRowIndex.forEach(sIndex => {
                  if (isRegistered) {
                    const campaignName =
                      allCampaigns?.find(c => c.id === aN.value.campaignId)
                        ?.name || "";
                    setValue(
                      `campaignId-${sIndex}`,
                      {
                        label: campaignName,
                        value: aN.value.campaignId
                      },
                      { absolutePath: false }
                    );
                  }
                  setValue(
                    `phoneData-${sIndex}`,
                    {
                      label: aN.label,
                      value: {
                        campaignId: aN.value.campaignId,
                        id: aN.value.id
                      }
                    },
                    { absolutePath: false }
                  );
                });
                setAreaCodeData(areaCodeData => {
                  const updatedAreaCodeData = [...areaCodeData];

                  selectedRowIndex.forEach(sIndex => {
                    updatedAreaCodeData[sIndex] = {
                      ...updatedAreaCodeData[sIndex],
                      campaignId: aN.value.campaignId,
                      phoneData: {
                        id: aN.value.id,
                        campaignId: aN.value.campaignId
                      }
                    };
                  });
                  return updatedAreaCodeData;
                });
                selectedRowIndex.length > 0 && submitForm && submitForm();
              }
            })
          );

          return (
            <Action
              type="data-grid"
              name=""
              items={[
                {
                  name: "Check All",
                  onClick: () => table.toggleAllRowsSelected(true)
                },
                {
                  name: "Uncheck All",
                  onClick: () => table.toggleAllRowsSelected(false)
                },

                {
                  name: "Check Unassigned",
                  onClick: () => table.setRowSelection(emptyPhoneNumberRows)
                },
                {
                  name: "Assign number",
                  actionItems: {
                    title: "Active Numbers",
                    items: activeNumberActions
                  }
                }
              ]}
            />
          );
        },
        cell: ({ row }: CellContext<any, unknown>) => {
          const checked = row.getIsSelected();
          const isIndeterminate = !checked && row.getIsSomeSelected();
          const value = isIndeterminate || checked;
          return (
            <CheckboxRoot>
              <CheckboxInput
                disabled={isCompleted || !row.getCanSelect()}
                isIndeterminate={isIndeterminate}
                label=""
                name={`cell-${row.index}`}
                size={16}
                // @ts-ignore
                value={!!value}
                onChange={(value: any) => row.toggleSelected(value as boolean)}
              />
            </CheckboxRoot>
          );
        }
      },
      {
        id: "areaCode",
        accessorKey: "areaCode",
        header: "Area Code",
        inputType: "textField" as CellInputType,
        maxSize: 1,
        meta: { disabled: true }
      },
      {
        id: "timesUsed",
        accessorKey: "timesUsed",
        header: "Times Used",
        inputType: "textField" as CellInputType,
        maxSize: 1,
        meta: { disabled: true }
      },
      ...(campaignType === CampaignType.Registered
        ? [
            {
              id: "campaignId",
              accessorKey: "campaignId",
              header: "Campaign",
              cell: CampaignSearchFieldCell
            }
          ]
        : []),
      {
        id: "phoneData",
        accessorKey: "phoneData",
        header: "Phone Number",
        cell: PhoneSearchFieldCell
      }
    ];
  }, [
    areaCodeData,
    campaignType,
    PhoneSearchFieldCell,
    CampaignSearchFieldCell,
    selectedRows
  ]);

  // infinite scroll settings
  const fetchNextPage = async () => {
    await refetchQuery({
      after: areaCodeData[areaCodeData.length - 1]?.cursor // Provide the cursor of the last item in the current data set
    }).then((newData: any) => {
      setRetrievedAreaCodes(prevData => [
        ...prevData,
        ...(newData?.data?.projectAreaCodes.edges || [])
      ]);
    });
  };

  return (
    <>
      <Root>
        <Table
          columns={columns}
          fixedHeader
          data={areaCodeData}
          loaderCount={20}
          variant="data-grid"
          setData={setAreaCodeData}
          fetchNextPage={fetchNextPage}
          customStyle="max-height: 600px; overflow-y: auto; "
          notSortable
          rowSelection
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          withRowSelectionColumn={false}
        />
      </Root>
    </>
  );
};

export default ProjectPhonesFields;
