// react modules
import React from "react";

// third-party modules
import _ from "lodash";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

// app modules
import {
  BasePhoneFieldsFragment,
  useCreatePhoneMutation,
  useGetPhoneQuery,
  useNewPhoneQuery,
  useUpdatePhoneMutation
} from "../../generated/graphql";
import {
  PHONE_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS,
  PHONES_PATH
} from "../../constants";
import sections, {
  PhoneGeneralFormOmitValues as formOmitValues
} from "./sections";
import DataOverview from "../../components/pages/DataOverview";
import ListPage from "../../components/pages/ListPage";
import PhoneAnchor from "./PhoneAnchor";
import PhoneView from "./PhoneShow";

const PhonesRoute = new ResourceRoute({
  name: "Phone",
  path: PHONES_PATH,
  AnchorComponent: PhoneAnchor,
  IndexComponent: () => <ListPage variant="Phones" />,
  formProps: {
    autosave: true,
    scrollable: true,
    sections,
    // @ts-ignore
    hydrate: [useNewPhoneQuery, "newPhone"],
    // @ts-ignore
    query: [useGetPhoneQuery, "phone"],
    // @ts-ignore
    create: [useCreatePhoneMutation, "createPhone"],
    // @ts-ignore
    update: [useUpdatePhoneMutation, "updatePhone"],
    normalize: (phone: BasePhoneFieldsFragment) => {
      if (!phone) return phone;

      phone.number = phone.number ? phone.number.replace(/[-()\s_]/g, "") : "";

      return {
        ..._.omit(phone, formOmitValues)
      };
    }
  },
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Phone",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Sent Texts",
      path: "sent_texts",
      baseRoute: "Phone",
      IndexComponent: ({ id }: { id: string }) => (
        <PhoneView id={id} activeLinkId={VIEW_LINKS.SENT_TEXTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Received Texts",
      path: "received_texts",
      baseRoute: "Phone",
      IndexComponent: ({ id }: { id: string }) => (
        <PhoneView id={id} activeLinkId={VIEW_LINKS.RECEIVED_TEXTS} />
      )
    })
  ]
});

export { PhonesRoute };
