import React from "react";

import { Table } from "@onehq/anton";
import { TextDefaultFieldsListFragment } from "../../generated/graphql";
import { BaseTableProps } from "../../types";

export type TextDefaultTableProps =
  BaseTableProps<TextDefaultFieldsListFragment>;

export const TextDefaultTableColumns = [
  {
    header: "Key",
    accessorKey: "field",
    disableSortBy: true
  },
  {
    header: "Value",
    accessorKey: "value",
    disableSortBy: true
  }
];

export const textDefaultTableDataFormatter = (
  data?: Array<TextDefaultFieldsListFragment>
) => [
  ...(data?.map((item: TextDefaultFieldsListFragment) => {
    const { field, value } = item;
    return {
      field,
      value
    };
  }) || [])
];

const TextDefaultTable = ({ data = [], ...props }: TextDefaultTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  const tableData = textDefaultTableDataFormatter(data);

  return (
    <Table
      columns={TextDefaultTableColumns}
      data={tableData}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default TextDefaultTable;
