export const PHONES_PATH = "phones";

export const PHONE_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  RECEIVED_TEXTS: "Received Texts",
  SENT_TEXTS: "Sent Texts"
};

export const PHONE_MENU_ANCHOR_EDIT_LINKS = {
  GENERAL: "General"
};
