// third-party modules
import { FormSection } from "@onehq/framework";

// app modules
import CampaignGeneralForm from "./CampaignGeneralForm";
import CampaignPhonesForm from "./CampaignPhonesForm";

export * from "./CampaignGeneralForm";
export default [
  new FormSection("General", CampaignGeneralForm),
  new FormSection("Phones", CampaignPhonesForm)
];
