import { LinkId } from "@onehq/anton";
import React, { useEffect, useMemo, useState } from "react";
import {
  ADD,
  GrowlAlertDispatch,
  List,
  useDispatchGrowlContext
} from "@onehq/framework";

import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../../../components/Text/TextTable";
import {
  useGetProjectTeamsListQuery,
  useGetProjectTextersListLazyQuery,
  useGetTeamsListLazyQuery,
  useGetTextsListLazyQuery,
  TextQueryFilterFields,
  ProjectTeamQueryFilterFields,
  ProjectTexterQueryFilterFields,
  TeamQueryFilterFields,
  FilterOperation,
  TextType,
  GetTextsListQueryVariables,
  GetProjectTextersListQueryVariables,
  GetTeamsListQueryVariables,
  useTextInsightsLazyQuery,
  TextInsightsQueryVariables,
  TextStatus,
  useGetOptOutTextsListLazyQuery,
  useGetProjectQuery,
  ProjectFieldsFragment,
  BaseTextFieldsFragment,
  LoadSendProjectOption,
  LoadSendProjectDocument,
  useGetListSummaryLazyQuery,
  ListSummaryOption,
  GetListSummaryQueryVariables
} from "../../../generated/graphql";
import { PROJECT_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW } from "../../../constants/projectConstants";
import ToPhoneTabFilter from "../../../utils/ToPhoneTabFilter";
import DataOverview from "../../../components/pages/DataOverview";
import {
  TeamTableColumns,
  teamTableDataFormatter
} from "../../../components/Team/TeamTable";
import {
  ProjectTexterTableColumns,
  projectTexterDataFormatter
} from "../../../components/ProjectTexter/ProjectTexterTable";
import {
  PAGE_SIZE,
  addErrorAlert,
  addSpacesBetweenWords
} from "../../../utils";
import {
  TextInsightsTableColumns,
  textInsightsDataFormatter
} from "../../../components/Text/TextInsightsTable";
import ProjectTestTexts from "../ProjectAnchor/ProjectTestTexts";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import {
  getRowTableColumns,
  rowTableDataFormatter
} from "../../../components/Row/RowTable";
import TextTypeSelect from "./TextTypeSelect";

interface ProjectShowProps {
  id: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const ProjectShow = ({ id, ...props }: ProjectShowProps) => {
  const location = useLocation();
  const textType = (location.state as { textType?: string })?.textType;

  const [selectedToPhone, setSelectedToPhone] = useState<any>(null);
  const [tabFilters, setTabFilters] = useState<Object[]>([]);

  const [selectedTextType, setSelectedTextType] = useState<string>(
    TextStatus.Delivered
  );

  useEffect(() => {
    setSelectedTextType(textType?.replace(" ", "") || TextStatus.Delivered);
  }, [textType]);

  const QueryVariables = {
    first: PAGE_SIZE,
    filters: [
      {
        field: TextQueryFilterFields.ProjectId,
        operation: FilterOperation.Equal,
        value: id
      }
    ]
  };

  // getting texts
  const [getTextsQuery, { data: textsData, loading: loadingTexts }] =
    useGetTextsListLazyQuery({
      variables: {
        ...QueryVariables
      },
      onCompleted: response => {
        props.setTotalPages && props.setTotalPages(response?.texts?.totalCount);
      }
    });

  const [
    getOptOutTextsQuery,
    { data: optOutTextsData, loading: loadingOptOutTexts }
  ] = useGetOptOutTextsListLazyQuery({
    variables: {
      ...QueryVariables
    },
    onCompleted: response => {
      props.setTotalPages &&
        props.setTotalPages(response?.optOutTexts?.totalCount);
    }
  });

  // getting duplicated, invalids or optouts phones
  const listSummaryOption =
    props.activeLinkId === LIST_VIEW.DUPLICATE_PHONES
      ? ListSummaryOption.Duplicates
      : props.activeLinkId === LIST_VIEW.INVALID_PHONES
      ? ListSummaryOption.Invalids
      : ListSummaryOption.OptOuts;
  const [getListSummaryQuery, { data: rowsData, loading: loadingRows }] =
    useGetListSummaryLazyQuery({
      variables: {
        projectId: id,
        option: listSummaryOption,
        first: PAGE_SIZE
      },
      onCompleted: response => {
        props.setTotalPages &&
          props.setTotalPages(response?.listSummary?.totalCount);
      }
    });

  const filterGetListSummary = (
    options?: Partial<GetListSummaryQueryVariables>
  ) => {
    return getListSummaryQuery({
      variables: {
        projectId: id,
        option: listSummaryOption,
        ...options
      }
    });
  };

  const [sendTextQuery] = useLazyQuery(LoadSendProjectDocument);

  const filterGetTexts = (
    options?: GetTextsListQueryVariables,
    extraFilters?: Object[],
    textType?: TextType,
    isOptOut?: boolean
  ) => {
    const extra = extraFilters || [];
    const typeFilter = textType
      ? [
          {
            field: TextQueryFilterFields.TextTypeId,
            operation: FilterOperation.Equal,
            value: `TextType::::${textType}`
          }
        ]
      : [
          {
            field: TextQueryFilterFields.TextTypeId,
            operation: FilterOperation.In,
            arrayValues: [
              `TextType::::${TextType.List}`,
              `TextType::::${TextType.Manual}`
            ]
          }
        ];

    return isOptOut
      ? getOptOutTextsQuery({
          variables: {
            ...QueryVariables,
            ...options,
            // @ts-ignore
            filters: [...QueryVariables.filters, ...typeFilter, ...extra]
          }
        })
      : getTextsQuery({
          variables: {
            ...QueryVariables,
            ...options,
            // @ts-ignore
            filters: [...QueryVariables.filters, ...typeFilter, ...extra]
          }
        });
  };

  // getting project texters
  const [
    getProjectTextersQuery,
    { data: projectTextersData, loading: loadingProjectTexters }
  ] = useGetProjectTextersListLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: ProjectTexterQueryFilterFields.ProjectId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    },
    onCompleted: response => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages &&
        props.setTotalPages(response.projectTexters.totalCount);
    }
  });
  const filterGetProjectTexters = (
    variables: GetProjectTextersListQueryVariables
  ) => getProjectTextersQuery({ variables });

  // getting text insights
  const [
    getTextInsightsQuery,
    { data: textInsightsData, loading: loadingTextInsights }
  ] = useTextInsightsLazyQuery({
    fetchPolicy: "no-cache",
    variables: { projectId: id, first: PAGE_SIZE }
  });

  const filterTextInsights = (variables: TextInsightsQueryVariables) =>
    getTextInsightsQuery({ variables });

  // getting project teams related to this project
  const {
    data: projectTeamsData,
    loading: projectTeamsloading,
    error: projectTeamsError
  } = useGetProjectTeamsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: ProjectTeamQueryFilterFields.ProjectId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  const hasValues =
    !!projectTeamsData &&
    !projectTeamsloading &&
    !projectTeamsError &&
    !!projectTeamsData.projectTeams?.nodes;
  const teamsInProject = hasValues
    ? projectTeamsData.projectTeams?.nodes?.map(pt => pt?.teamId)
    : [];

  // getting teams to show, using the projectTeams list to make the filters
  const [getTeamsQuery, { data: teamsData, loading: loadingTeams }] =
    useGetTeamsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        first: PAGE_SIZE,
        // @ts-ignore
        filters: hasValues
          ? [
              {
                field: TeamQueryFilterFields.Id,
                operation: FilterOperation.In,
                arrayValues: teamsInProject
              }
            ]
          : []
      },
      onCompleted: response => {
        props.setTotalPages && props.setTotalPages(response.teams.totalCount);
      }
    });
  const filterGetTeams = (variables: GetTeamsListQueryVariables) =>
    getTeamsQuery({ variables });

  useEffect(() => {
    const toPhoneIdInTexts =
      selectedToPhone?.map(
        (object: { label: string; value: string }) => object?.value
      ) || [];
    const FilterQueryVariables2 =
      toPhoneIdInTexts?.length > 0
        ? [
            {
              field: TextQueryFilterFields.ToPhoneId,
              operation: FilterOperation.In,
              arrayValues: toPhoneIdInTexts
            }
          ]
        : [];

    setTabFilters(FilterQueryVariables2);
  }, [selectedToPhone, setSelectedToPhone]);

  const TabFilter = () => {
    return (
      <ToPhoneTabFilter
        placeholder={"Search a 'To Phone Number'"}
        resource={selectedToPhone}
        setResource={setSelectedToPhone}
      />
    );
  };

  // this is a hook, so we call it here
  const formattedTexts = useTextTableDataFormatter(
    // @ts-ignore
    textsData?.texts?.nodes || [],
    (
      toPhone: BaseTextFieldsFragment["toPhone"],
      fromPhone: BaseTextFieldsFragment["fromPhone"],
      message: string
    ) => {
      setShowTestTexts(true);
      setSelectedTextToPhone(toPhone);
      setSelectedTextFromPhone(fromPhone);
      setTextMessage(message);
    }
  );
  const formattedOptOutTexts = useTextTableDataFormatter(
    // @ts-ignore
    optOutTextsData?.optOutTexts?.nodes || []
  );

  const [showTestTexts, setShowTestTexts] = useState(true);
  const [selectedTextToPhone, setSelectedTextToPhone] =
    useState<BaseTextFieldsFragment["toPhone"]>();
  const [selectedTextFromPhone, setSelectedTextFromPhone] =
    useState<BaseTextFieldsFragment["fromPhone"]>();
  const [textMessage, setTextMessage] = useState<string>();

  const { data: projectData } = useGetProjectQuery({
    fetchPolicy: "cache-and-network",
    context: {
      headers: {
        isView: "true",
        resource: "Project"
      }
    },
    variables: { id }
  });
  const project = useMemo(() => {
    return { ...projectData?.project } as ProjectFieldsFragment;
  }, [projectData]);
  const fromPhones = useMemo(() => {
    const selected = selectedTextFromPhone;
    const projectPhonePhones = project?.projectPhones?.map(p => p.phone);
    const cleanFromPhones = Array.from(new Set(projectPhonePhones));
    const index = cleanFromPhones.findIndex(p => p.id === selected?.id);
    if (index > -1) cleanFromPhones.splice(index, 1);
    return selected ? [selected, ...cleanFromPhones] : cleanFromPhones;
  }, [project, selectedTextFromPhone]);

  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  const sendText = (phoneTo: string[], body = "", fromPhoneId?: string) => {
    setShowTestTexts(false);
    void sendTextQuery({
      fetchPolicy: "no-cache",
      variables: {
        projectId: id,
        option: LoadSendProjectOption.TestText,
        phoneIds: phoneTo,
        body,
        fromPhoneId
      },
      onCompleted: response => {
        const errors =
          (response?.loadSendProject?.errors as string[] | undefined) || [];
        if (errors?.length > 0) {
          addErrorAlert(alert, "Error, text not sent", errors.join(". "));
        } else {
          alert({
            type: ADD,
            payload: {
              title: "All changes saved",
              message: response.loadSendProject.message,
              variant: "success"
            }
          });
          void filterGetTexts(undefined, [], TextType.Test);
        }
      },
      onError: err => {
        addErrorAlert(alert, "Error, text not sent", err.message);
        console.error(err.message); // the error if that is the case
      }
    });
  };

  // reset counter in overview view
  if (
    props.setTotalPages &&
    (props.activeLinkId === LIST_VIEW.OVERVIEW ||
      props.activeLinkId === LIST_VIEW.TEXTS_INSIGHTS)
  )
    props.setTotalPages(null);
  return (
    <>
      {props.activeLinkId === LIST_VIEW.TEXTS && (
        <TextTypeSelect
          selectedTextType={selectedTextType}
          setSelectedTextType={setSelectedTextType}
        />
      )}
      {props.activeLinkId !== LIST_VIEW.OVERVIEW &&
        props.activeLinkId !== LIST_VIEW.TEXTERS &&
        props.activeLinkId !== LIST_VIEW.TEAMS &&
        props.activeLinkId !== LIST_VIEW.TEXTS_INSIGHTS &&
        props.activeLinkId !== LIST_VIEW.TEST_TEXTS &&
        props.activeLinkId !== LIST_VIEW.SEED_TEXTS && <TabFilter />}
      {props.activeLinkId === LIST_VIEW.TEAMS && (
        <List
          accessor="teams"
          columns={TeamTableColumns}
          data={teamsData}
          dataFormat={teamTableDataFormatter}
          loading={loadingTeams}
          refetchQuery={filterGetTeams}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTERS && (
        <List
          accessor="projectTexters"
          columns={ProjectTexterTableColumns}
          data={projectTextersData}
          dataFormat={projectTexterDataFormatter}
          loading={loadingProjectTexters}
          refetchQuery={filterGetProjectTexters}
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEST_TEXTS && (
        <>
          <List
            accessor="texts"
            columns={getTextTableColumns({ testTextTable: true })}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [], TextType.Test)
            }
          />
          {showTestTexts && selectedTextToPhone && textMessage && (
            <ProjectTestTexts
              projectName={project.name}
              message={textMessage}
              projectMediaUrl={project.mediaUrl}
              projectMediaPosition={project.mediaPosition}
              managerPhones={project.manager.phones}
              fromPhones={fromPhones}
              clientPhones={project.client.phones}
              alreadySelectedPhone={selectedTextToPhone}
              projectMessage={project.message}
              onSend={sendText}
              onClose={() => {
                setShowTestTexts(false);
              }}
            />
          )}
        </>
      )}
      {props.activeLinkId === LIST_VIEW.SEED_TEXTS && (
        <>
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [], TextType.Seed)
            }
          />
        </>
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Delivered" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${TextStatus.Delivered}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Clicked" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${TextStatus.Clicked}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Failed" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [
              {
                field: TextQueryFilterFields.TextStatusId,
                operation: FilterOperation.Equal,
                value: `TextStatus::::${TextStatus.Failed}`
              },
              ...tabFilters
            ])
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Queued" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [
              {
                field: TextQueryFilterFields.TextStatusId,
                operation: FilterOperation.Equal,
                value: `TextStatus::::${TextStatus.Queued}`
              },
              ...tabFilters
            ])
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Expired" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${TextStatus.Expired}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Spam" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [
              {
                field: TextQueryFilterFields.TextStatusId,
                operation: FilterOperation.Equal,
                value: `TextStatus::::${TextStatus.Spam}`
              },
              ...tabFilters
            ])
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "NumberFormat" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${addSpacesBetweenWords(
                    TextStatus.NumberFormat
                  )}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "MediaSize" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${addSpacesBetweenWords(
                    TextStatus.MediaSize
                  )}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Received" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(
                options,
                [
                  {
                    field: TextQueryFilterFields.TextStatusId,
                    operation: FilterOperation.Equal,
                    value: `TextStatus::::${TextStatus.Received}`
                  },
                  ...tabFilters
                ],
                TextType.Reply
              )
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "RateLimited" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${addSpacesBetweenWords(
                    TextStatus.RateLimited
                  )}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Rejected" && (
          <List
            accessor="texts"
            // get error msg column as well
            columns={getTextTableColumns({ showError: true })}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${TextStatus.Rejected}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Late" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [
              {
                field: TextQueryFilterFields.TextStatusId,
                operation: FilterOperation.Equal,
                value: `TextStatus::::${TextStatus.Late}`
              },
              ...tabFilters
            ])
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Sending" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${TextStatus.Sending}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Sent" && (
        <List
          accessor="texts"
          // get error msg column as well
          columns={getTextTableColumns({ showError: true })}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [
              {
                field: TextQueryFilterFields.TextStatusId,
                operation: FilterOperation.Equal,
                value: `TextStatus::::${TextStatus.Sent}`
              },
              ...tabFilters
            ])
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Active" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [
              {
                field: TextQueryFilterFields.TextStatusId,
                operation: FilterOperation.Equal,
                value: `TextStatus::::${TextStatus.Active}`
              },
              ...tabFilters
            ])
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Error" && (
        <List
          accessor="texts"
          // get error msg column as well
          columns={getTextTableColumns({ showError: true })}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [
              {
                field: TextQueryFilterFields.TextStatusId,
                operation: FilterOperation.Equal,
                value: `TextStatus::::${TextStatus.Error}`
              },
              ...tabFilters
            ])
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Loading" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${TextStatus.Loading}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "Ready" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [
              {
                field: TextQueryFilterFields.TextStatusId,
                operation: FilterOperation.Equal,
                value: `TextStatus::::${TextStatus.Ready}`
              },
              ...tabFilters
            ])
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "Undelivered" && (
          <List
            accessor="texts"
            columns={getTextTableColumns()}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${TextStatus.Undelivered}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "UpdateFailed" && (
          <List
            accessor="texts"
            // get error msg column as well
            columns={getTextTableColumns({ showError: true })}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::${addSpacesBetweenWords(
                    TextStatus.UpdateFailed
                  )}`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS &&
        selectedTextType === "RateLimited" && (
          <List
            accessor="texts"
            // get error msg column as well
            columns={getTextTableColumns({ showError: true })}
            data={{
              ...textsData,
              texts: {
                ...textsData?.texts,
                nodes: formattedTexts
              }
            }}
            loading={loadingTexts}
            refetchQuery={(options?: Object) =>
              filterGetTexts(options, [
                {
                  field: TextQueryFilterFields.TextStatusId,
                  operation: FilterOperation.Equal,
                  value: `TextStatus::::Rate Limited`
                },
                ...tabFilters
              ])
            }
          />
        )}
      {props.activeLinkId === LIST_VIEW.TEXTS && selectedTextType === "OptOut" && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...optOutTextsData,
            texts: {
              ...optOutTextsData?.optOutTexts,
              nodes: formattedOptOutTexts
            }
          }}
          loading={loadingOptOutTexts}
          refetchQuery={(options?: Object) =>
            filterGetTexts(options, [], undefined, true)
          }
        />
      )}
      {props.activeLinkId === LIST_VIEW.TEXTS_INSIGHTS && (
        <List
          accessor="textInsights"
          columns={TextInsightsTableColumns}
          data={textInsightsData}
          dataFormat={textInsightsDataFormatter}
          loading={loadingTextInsights}
          refetchQuery={filterTextInsights}
        />
      )}
      {props.activeLinkId === LIST_VIEW.DUPLICATE_PHONES && (
        <List
          accessor="listSummary"
          columns={getRowTableColumns()}
          data={rowsData}
          dataFormat={rowTableDataFormatter}
          loading={loadingRows}
          refetchQuery={(options?: Object) => filterGetListSummary(options)}
        />
      )}
      {props.activeLinkId === LIST_VIEW.INVALID_PHONES && (
        <List
          accessor="listSummary"
          columns={getRowTableColumns()}
          data={rowsData}
          dataFormat={rowTableDataFormatter}
          loading={loadingRows}
          refetchQuery={(options?: Object) => filterGetListSummary(options)}
        />
      )}
      {props.activeLinkId === LIST_VIEW.OPT_OUT_PHONES && (
        <List
          accessor="listSummary"
          columns={getRowTableColumns()}
          data={rowsData}
          dataFormat={rowTableDataFormatter}
          loading={loadingRows}
          refetchQuery={(options?: Object) => filterGetListSummary(options)}
        />
      )}
      {props.activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
    </>
  );
};

export default ProjectShow;
