import React from "react";

import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  FilterOperation,
  GetProjectsListQuery,
  GetTextsListQuery,
  ProjectQueryFilterFields,
  ProjectTexterQueryFilterFields,
  TextQueryFilterFields,
  useGetProjectsListLazyQuery,
  useGetProjectTextersListQuery,
  useGetTextsListLazyQuery
} from "../../../generated/graphql";
import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../../../components/Text/TextTable";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";
import { USER_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS } from "../../../constants";
import DataOverview from "../../../components/pages/DataOverview";

interface UserShowProps {
  id: string;
  setTotalPages?: Function;
  activeLinkId: LinkId;
}

const UserShow = ({ id, ...props }: UserShowProps) => {
  const [getTextsQuery, { data: textsData, loading: loadingTexts }] =
    useGetTextsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        filters: [
          {
            field: TextQueryFilterFields.TexterId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      onCompleted: (response: GetTextsListQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props.setTotalPages && props.setTotalPages(response.texts.totalCount);
      }
    });

  const formattedTexts = useTextTableDataFormatter(
    // @ts-ignore
    textsData?.texts?.nodes || []
  );

  // getting project texters related to this user
  const { data, loading, error } = useGetProjectTextersListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: ProjectTexterQueryFilterFields.UserId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  const hasValues =
    !!data && !loading && !error && !!data.projectTexters?.nodes;

  // getting projects to show, using the projectTexters list to make the filters
  const [getProjectsQuery, { data: projectsData, loading: loadingProjects }] =
    useGetProjectsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        // @ts-ignore
        filters: hasValues
          ? [
              {
                field: ProjectQueryFilterFields.Id,
                operation: FilterOperation.In,
                arrayValues: data.projectTexters?.nodes?.map(
                  pt => pt?.projectId
                )
              }
            ]
          : []
      },
      onCompleted: (response: GetProjectsListQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props.setTotalPages &&
          props.setTotalPages(response.projects.totalCount);
      }
    });

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === VIEW_LINKS.OVERVIEW)
    props.setTotalPages(null);

  return (
    <>
      {props.activeLinkId === VIEW_LINKS.OVERVIEW && <DataOverview />}
      {props.activeLinkId === VIEW_LINKS.TEXTS && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={getTextsQuery}
        />
      )}
      {props.activeLinkId === VIEW_LINKS.PROJECTS && (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectsData}
          dataFormat={projectTableDataFormatter}
          loading={loadingProjects}
          refetchQuery={getProjectsQuery}
        />
      )}
    </>
  );
};

export default UserShow;
