/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useEffect, useMemo, useRef, useState } from "react";

// third-party modules
import {
  Button,
  CellInputType,
  ConfirmationModal,
  Fieldset,
  FormBuilder,
  Label,
  NestedForm,
  NumberField,
  SearchField,
  Table,
  useWatchContext
} from "@onehq/anton";
import {
  ADD,
  GrowlAlertDispatch,
  REMOVE,
  useDispatchGrowlContext
} from "@onehq/framework";
import styled from "styled-components";

// app modules
import {
  FilterOperation,
  PhoneQueryFilterFields,
  PhoneType,
  useGetPhonesListQuery,
  useDestroyPhoneMutation,
  PhoneTypeGroup,
  useGetPhonesListLazyQuery,
  useGetPhoneTypeOptionsQuery,
  useReleaseNumberMutation,
  useSearchPhoneNumbersLazyQuery,
  useBuyNumberMutation,
  CampaignQueryFilterFields,
  useGetCampaignsListLazyQuery,
  useUpdateClientMutation
} from "../../../generated/graphql";
import { StyledFieldset, TableContainer } from "./index.styles";
import {
  addErrorAlert,
  formatCampaignList,
  formatCampaignOption,
  formatPhone,
  PAGE_SIZE
} from "../../../utils";
import { buttonActionCell } from "./utils";
import { DEFAULT_LIMIT } from "../../../constants";
import { CellContext } from "@tanstack/react-table";

// set select options over table
const OverTableSearchField = styled(SearchField)`
  z-index: 5;
`;

// table columns
const CLIENT_NUMBERS_TABLE_COLUMNS = [
  {
    id: "id",
    header: "ID",
    accessorKey: "id",
    inputType: "textField" as CellInputType,
    size: 50,
    meta: {
      disabled: true,
      placeholder: " "
    }
  },
  {
    id: "number",
    header: "Phone Number",
    accessorKey: "number",
    inputType: "phoneField" as CellInputType
  },
  {
    id: "description",
    header: "Description",
    accessorKey: "description",
    inputType: "textField" as CellInputType,
    disableSortBy: true
  },
  {
    id: "action",
    header: "Action",
    accessorKey: "action",
    disableSortBy: true,
    columnMaxWidth: 140,
    cell: buttonActionCell("Delete")
  }
];

const PURCHASED_NUMBERS_TABLE_COLUMNS = [
  {
    id: "campaign",
    header: "Campaign",
    accessorKey: "campaign",
    disableSortBy: true
  },
  {
    id: "number",
    header: "Phone Number",
    accessorKey: "number"
  },
  {
    id: "action",
    header: "Action",
    accessorKey: "action",
    disableSortBy: true,
    columnMaxWidth: 140,
    cell: buttonActionCell("Remove")
  }
];

const Text = styled.div`
  font-size: ${props => props.theme.font.base.size};
  padding: 0 ${props => props.theme.space.spacing8};
`;

const ClientNumbersForm = () => {
  // id watcher this is for know if its edition or creation
  const id = useWatchContext("id");

  // hook for get a function to create alerts
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  // clientNumbers refForm to get its submit function
  const refClientNumbersForm = useRef<HTMLFormElement>(null);

  // buyNumber refForm to get its submit function
  const refBuyNumberForm = useRef<HTMLFormElement>(null);
  const searchNumbersOnSubmit = () => {
    if (refBuyNumberForm && refBuyNumberForm.current) {
      refBuyNumberForm.current.submit();
    }
  };

  // state variables
  const [purchasedPhones, setPurchasedPhones] = useState<any[]>([]);
  const [loadingPurchasedPhones, setLoadingPurchasedPhones] =
    useState<boolean>(true);
  const [loadingSearchPhones, setLoadingSearchPhones] =
    useState<boolean>(false);
  // search phones result available to buy
  const [searchPhones, setSearchPhones] = useState<
    {
      number: string;
      campaignId?: string;
      action: any;
    }[]
  >([]);
  // selected client number to delete or provider number to remove
  const [selectedNumber, setSelectedNumber] = useState<
    | {
        id?: string;
        number?: string;
        action: "delete" | "remove";
      }
    | undefined
  >(undefined);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const hasCampaigns = useMemo(() => campaigns.length > 0, [campaigns]);

  // QUERIES
  // get phones query (on first render)
  const {
    data: clientPhonesData,
    loading: loadingClientPhones,
    refetch: refetchClientPhones
  } = useGetPhonesListQuery({
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: PhoneQueryFilterFields.ClientId,
          operation: FilterOperation.Equal,
          value: id
        },
        {
          field: PhoneQueryFilterFields.PhoneTypeId,
          operation: FilterOperation.Equal,
          value: `PhoneType::::${PhoneType.Client}`
        }
      ]
    }
  });

  const [clientPhones, setClientPhones] = useState<any>([]);

  useEffect(() => {
    setClientPhones(
      clientPhonesData?.phones.nodes?.map(p => ({
        id: p?.id,
        number: formatPhone(p?.number || ""),
        description: p?.description,
        action: () =>
          setSelectedNumber({
            id: p?.id,
            number: p?.number,
            action: "delete"
          })
      }))
    );
  }, [clientPhonesData]);

  const clientPhonesInitialValues = useMemo(
    () =>
      clientPhonesData?.phones.nodes?.reduce(
        (acc, p, index) => ({
          ...acc,
          ...{
            [`id-${index}`]: p?.id,
            [`number-${index}`]: formatPhone((p?.number as string) || ""),
            [`description-${index}`]: p?.description
          }
        }),
        {}
      ),
    [clientPhonesData]
  );

  // get all provider group phone types (on first render)
  const { data: phoneTypesData, loading: loadingPhoneTypes } =
    useGetPhoneTypeOptionsQuery({
      fetchPolicy: "cache-and-network"
    });

  // get phones when phoneTypeOptions finishes loading
  useEffect(() => {
    if (id && !loadingPhoneTypes && phoneTypesData?.options.nodes?.length) {
      void fetchPurchasedPhones();
    } else if (!loadingPhoneTypes && !phoneTypesData) {
      // something went wrong fetching phoneTypeOptions, just unload phones
      setLoadingPurchasedPhones(false);
    }
  }, [loadingPhoneTypes, id]);

  // get phones lazyQuery (for purchasedPhones) when phoneTypes are fetched
  const [getPhonesList] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: response => {
      setLoadingPurchasedPhones(false);
      setPurchasedPhones(
        response?.phones.nodes?.map(p => ({
          id: p?.id,
          campaign: p?.campaign?.name,
          number: formatPhone(p?.number || ""),
          action: () =>
            setSelectedNumber({
              id: p?.id,
              number: p?.number,
              action: "remove"
            })
        })) || []
      );
    },
    onError: error => {
      setLoadingPurchasedPhones(false);
      console.log(error);
    }
  });
  // fetch purchased phones function, executed when phoneTypes are fetched
  const fetchPurchasedPhones = () =>
    getPhonesList({
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: PhoneQueryFilterFields.ClientId,
            operation: FilterOperation.Equal,
            value: id
          },
          {
            field: PhoneQueryFilterFields.PhoneTypeId,
            operation: FilterOperation.In,
            arrayValues: phoneTypesData?.options.nodes
              ?.filter(
                p => p?.groupId === `PhoneType::::${PhoneTypeGroup.Provider}`
              )
              .map(p => `PhoneType::::${p?.name}`)
          }
        ]
      }
    });

  // searchNumbers query for the table
  const [searchProviderNumbersQuery] = useSearchPhoneNumbersLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: response => {
      const errors = response.searchPhoneNumbers.errors;
      if (errors) {
        alert({
          type: ADD,
          payload: {
            title: "Error searching numbers",
            message: errors,
            variant: "error"
          }
        });
        setSearchPhones([]);
      } else {
        const searchPhoneNumbers = response.searchPhoneNumbers as string[];
        setSearchPhones(
          searchPhoneNumbers.map(s => ({
            number: formatPhone(s),
            campaignId: undefined,
            action: undefined
          }))
        );
      }
      setLoadingSearchPhones(false);
    },
    onError: error => {
      console.log(error);
      setLoadingSearchPhones(false);
      setSearchPhones([]);
    }
  });
  const searchNumbers = values => {
    setLoadingSearchPhones(true);
    void searchProviderNumbersQuery({
      variables: {
        areaCode: values.areaCode.toString(),
        campaignId: values.campaignId
      }
    });
  };

  const [getCampaigns] = useGetCampaignsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  useEffect(() => {
    if (id) {
      getCampaigns({
        variables: {
          limit: DEFAULT_LIMIT,
          filters: [
            {
              field: CampaignQueryFilterFields.ClientId,
              operation: FilterOperation.Equal,
              value: id
            }
          ]
        }
      })
        .then(response => {
          const campaigns = response.data?.campaigns?.nodes || [];
          setCampaigns(campaigns);
        })
        .catch(errors => console.log(errors));
    }
  }, [id]);

  const campaignOptions = useMemo(() => {
    return [
      {
        label: "Available Campaigns",
        options: formatCampaignList(
          campaigns.filter(campaign => campaign.canBuyMorePhones)
        )
      },
      {
        label: "Limit Reached Campaigns",
        options: campaigns
          .filter(campaign => !campaign.canBuyMorePhones)
          .map(campaign => {
            const option = formatCampaignOption(campaign) as any;
            option.isDisabled = true;
            return option;
          })
      }
    ];
  }, [campaigns]);

  // MUTATIONS
  const [updateClientMutation] = useUpdateClientMutation({
    onCompleted: response => {
      if (Object.keys(response?.updateClient?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "Number saved successfully",
            variant: "success"
          }
        });
        // blanking inputs if creation is successful
        // ASK FOR HELP, how can i blank inputs
        void refetchClientPhones();
      } else {
        alert({
          type: ADD,
          payload: {
            title: "Error saving number",
            variant: "error"
          }
        });
      }
    },
    onError: err => {
      console.error(err.message);
      alert({
        type: ADD,
        payload: {
          title: "Error saving number",
          variant: "error"
        }
      });
    }
  });

  const transformData = (data: { [keyName: string]: string }) => {
    const result: { id: string; description: string; number: string }[] = [];

    for (let i = 0; i < (Object.keys(data).length - 1) / 3; i++) {
      // if (data[`number-${i}`])
      result.push({
        id: data[`id-${i}`],
        description: data[`description-${i}`],
        number: data[`number-${i}`].replace(/[-()\s_]/g, "")
      });
    }

    return result;
  };

  const updateClientNumbers = async (value: {
    clientNumbers: { [keyName: string]: string };
  }) => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Saving Number",
        variant: "progress"
      }
    });
    await updateClientMutation({
      variables: {
        id,
        attributes: { phones: transformData(value.clientNumbers) }
      }
    }).then(() => {
      alert({
        type: REMOVE,
        payload: {
          id: idForAlert,
          title: "",
          variant: "success"
        }
      });
    });
  };

  // delete phone action for the table action
  const [destroyPhoneMutation] = useDestroyPhoneMutation({
    onCompleted: response => {
      if (Object.keys(response?.destroyPhone?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "Number deleted successfully",
            variant: "success"
          }
        });
        void refetchClientPhones();
      } else {
        response?.destroyPhone?.errors &&
          addErrorAlert(
            alert,
            "Error, client not loaded",
            // eslint-disable-next-line
            Object.keys(response?.destroyPhone?.errors)
              .map(el =>
                el === "base"
                  ? response.destroyPhone?.errors[el]
                  : `${el}: ${response.destroyPhone?.errors[el]}`
              )
              .join(". ")
          );
      }
    },
    onError: err => {
      console.error(err.message);
      alert({
        type: ADD,
        payload: {
          title: "Error deleting number",
          variant: "error"
        }
      });
    }
  });
  const deleteNumber = async id => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Deleting Number",
        variant: "progress"
      }
    });
    await destroyPhoneMutation({
      variables: {
        id
      }
    }).then(() => {
      alert({
        type: REMOVE,
        payload: {
          id: idForAlert,
          title: "",
          variant: "success"
        }
      });
    });
  };

  // remove purchased number, for the table action
  const [releaseNumberMutation] = useReleaseNumberMutation({
    onCompleted: response => {
      if (response.releaseNumber?.error) {
        alert({
          type: ADD,
          payload: {
            title: "Error removing number",
            message: response.releaseNumber.error,
            variant: "error"
          }
        });
      } else {
        void fetchPurchasedPhones();
        alert({
          type: ADD,
          payload: {
            title: "Number removed successfully",
            message: response.releaseNumber?.message || undefined,
            variant: "success"
          }
        });
      }
    },
    onError: error => {
      console.log(error);
      alert({
        type: ADD,
        payload: {
          title: "Error removing number",
          variant: "error"
        }
      });
    }
  });
  const releaseNumber = async (numberId: string) => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Removing Number",
        variant: "progress"
      }
    });
    await releaseNumberMutation({
      variables: {
        phoneId: numberId
      }
    }).then(() => {
      alert({
        type: REMOVE,
        payload: {
          id: idForAlert,
          title: "",
          variant: "success"
        }
      });
    });
  };

  // buy number mutation for the table action
  const [buyNumberMutation] = useBuyNumberMutation({
    onCompleted: response => {
      if (response.buyNumber?.errors) {
        alert({
          type: ADD,
          payload: {
            title: "Error buying number",
            message: response.buyNumber.errors,
            variant: "error"
          }
        });
      } else {
        void fetchPurchasedPhones();
        // void searchNumbersOnSubmit(); shoud i refetch search numbers?
        alert({
          type: ADD,
          payload: {
            title: "Number bought successfully",
            message: response.buyNumber?.message || undefined,
            variant: "success"
          }
        });
      }
    },
    onError: error => {
      console.log(error);
      alert({
        type: ADD,
        payload: {
          title: "Error buying number",
          variant: "error"
        }
      });
    }
  });
  const buyNumber = async (phoneNumber: string, campaignId: string) => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Buying Number",
        variant: "progress"
      }
    });
    await buyNumberMutation({
      variables: {
        phoneNumber,
        campaignId
      }
    }).then(() => {
      alert({
        type: REMOVE,
        payload: {
          id: idForAlert,
          title: "",
          variant: "success"
        }
      });
    });
  };

  const buyNumberTableColumns = useMemo(() => {
    return [
      {
        id: "number",
        header: "Phone Number",
        accessorKey: "number"
      },
      {
        id: "action",
        header: "Action",
        accessorKey: "action",
        disableSortBy: true,
        columnMaxWidth: 140,
        cell: ({ row }: CellContext<any, any>) => {
          const phoneNumber = row.getValue<string>("number");
          const unformattedNumber = phoneNumber.replace(/[^0-9]/g, "");

          if (purchasedPhones.some(p => p.number === phoneNumber)) {
            return undefined;
          }

          return (
            <Button
              variant="inline"
              onClick={() => {
                const elements = Array.from(
                  refBuyNumberForm.current?.formRef.current.elements as any[]
                );
                const campaignId = elements[1].value as string;
                void buyNumber(unformattedNumber, campaignId);
              }}
            >
              Buy This Number
            </Button>
          );
        }
      }
    ];
  }, [campaigns, purchasedPhones]);

  // this form will not appear if client doesnt exist yet (its creation)
  if (!id) return null;

  const deleteAction = selectedNumber?.action === "delete";
  return (
    <>
      <Fieldset legend={"Internal Phone Numbers"}>
        <FormBuilder
          ref={refClientNumbersForm}
          autosave
          values={{ clientNumbers: clientPhonesInitialValues }}
          onSubmit={updateClientNumbers}
        >
          <NestedForm
            name="clientNumbers"
            addable={false}
            hasOne // will always have just one table
            component={() => (
              <TableContainer>
                <Table
                  variant="data-grid"
                  addRows
                  columns={CLIENT_NUMBERS_TABLE_COLUMNS}
                  data={clientPhones || [{}]}
                  leftAligned
                  setData={setClientPhones}
                  skeleton={loadingClientPhones}
                />
              </TableContainer>
            )}
            condensed
          />
        </FormBuilder>
      </Fieldset>
      <Fieldset legend={"Purchased Numbers"}>
        <TableContainer>
          {/* this is a styled default table (values cant be modified) */}
          <Table
            name="purchased numbers"
            leftAligned
            variant="data-grid"
            setData={() => console.log(purchasedPhones)}
            skeleton={loadingPurchasedPhones}
            data={purchasedPhones || []}
            columns={PURCHASED_NUMBERS_TABLE_COLUMNS}
          />
        </TableContainer>
      </Fieldset>
      <Fieldset legend={"Buy Numbers"}>
        {!hasCampaigns && (
          <Text>
            To purchase numbers for sending messages, please create campaigns
            first. The numbers will be associated with these campaigns.
          </Text>
        )}
        {hasCampaigns && (
          <FormBuilder
            ref={refBuyNumberForm}
            autosave={false}
            values={{}}
            /* @ts-ignore */
            onSubmit={searchNumbers}
          >
            {/* @ts-ignore */}
            <OverTableSearchField
              span={9}
              label="Campaign"
              name="campaignId"
              required
              defaultOptions={campaignOptions}
              loadOptions={(text: string) => {
                const availables = campaigns.filter(
                  campaign => campaign.canBuyMorePhones
                );
                return Promise.resolve(
                  formatCampaignList(availables).filter(option =>
                    option.label.includes(text)
                  )
                );
              }}
            />
            <NumberField span={9} label="Area Code" name="areaCode" required />
            {/* styling the button so it behaves (in styling) like a field */}
            <StyledFieldset span={6}>
              <Label text=" ">
                <Button
                  fullWidth
                  icon="search"
                  variant="secondary"
                  onClick={searchNumbersOnSubmit}
                >
                  Search Number
                </Button>
              </Label>
            </StyledFieldset>
          </FormBuilder>
        )}
        {hasCampaigns && (
          <TableContainer>
            <Table
              hideHeader
              name="provider numbers"
              skeleton={loadingSearchPhones}
              data={searchPhones}
              setData={setSearchPhones}
              columns={buyNumberTableColumns}
              variant="data-grid"
            />
          </TableContainer>
        )}
      </Fieldset>
      <ConfirmationModal
        message={`Are you sure you want to ${
          deleteAction ? "delete" : "remove"
        } this number? ${formatPhone(selectedNumber?.number || "")}`}
        title={`${deleteAction ? "Delete client" : "Remove provider"} number?`}
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={!!selectedNumber}
        handleClose={() => setSelectedNumber(undefined)}
        onConfirm={() =>
          deleteAction
            ? deleteNumber(selectedNumber?.id || "")
            : releaseNumber(selectedNumber?.id || "")
        }
      />
    </>
  );
};

export default ClientNumbersForm;
