/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// third-party modules
import {
  Progress,
  SpeedWork,
  Work as WorkV3,
  WorkProps as WorkV3Props
} from "@onehq/anton";
import { useLazyQuery } from "@apollo/client";

// app modules
import {
  WorkItemEdge,
  GetWorkItemsDocument,
  SendTextDocument,
  GetWorkProgressDocument,
  GetWorkListDocument,
  useCustomCurrentUserQuery
} from "../../generated/graphql";
import { QueryParams, WorkData, WorkItemType, WorkProps } from "./types";
import {
  formatItems,
  formatWorkList,
  hasNextItemInPage,
  hasPreviousItemInPage,
  sortByName
} from "./utils";
import { WindowSizeContext } from "../../routes";
import {
  CLIENTS_PATH,
  LISTS_PATH,
  PHONES_PATH,
  PROJECTS_PATH,
  TEAMS_PATH,
  TEXTS_PATH,
  USERS_PATH,
  WORK_PATH
} from "../../constants";

const Work = (props: WorkProps) => {
  const location = useLocation();

  const { data: currentUser } = useCustomCurrentUserQuery();
  const user = currentUser?.currentUser; // for short

  const hasWork = user?.work && user.work.length > 0;
  // set workDataList from props if its defined, if its not, set from currentUser
  const workDataList = props.workDataList
    ? props.workDataList
    : hasWork
    ? formatWorkList(user.work)
    : [];

  const [workList, setWorkList] = useState<WorkData[]>([]);
  const [currentWork, setCurrentWork] = useState<WorkData>();
  const [currentWorkChecked, setCurrentWorkChecked] = useState(false);
  const [message, setMessage] = useState("");
  const [showSpeedMode, setShowSpeedMode] = useState(false);
  const [communication, setCommunication] =
    useState<WorkV3Props["communicationMethod"]>();
  const navigateTo = useNavigate();

  const [getWorkItems] = useLazyQuery(GetWorkItemsDocument);
  const [sendText] = useLazyQuery(SendTextDocument);
  const [getProgress] = useLazyQuery(GetWorkProgressDocument);
  const [getWorkList] = useLazyQuery(GetWorkListDocument);

  const windowObserver = useContext(WindowSizeContext);
  useEffect(() => {
    // this check is here in order to only load data the first time we load the user's work
    // this way we can ignore all the updates in the work.currentItemId (triggers refetch of data)
    if (!currentWork && workDataList.length > 0) {
      sortByName(workDataList);
      setWorkList(workDataList);
      setCurrentWork({ ...workDataList[0] });
    }
  }, [workDataList]);

  useEffect(() => {
    if (currentWork && !currentWork.paginationInfo.loading) {
      // when loading the work, it's list of pages is empty at the start
      if (currentWork.status === "ready") {
        loadItemsPage(currentWork, "current");
      }
      setMessage(currentWork.currentItem?.contact?.message || "");

      const currWorkIndex = workList.findIndex(w => w.id === currentWork.id);
      workList[currWorkIndex] = currentWork;
      setWorkList([...workList]);
    }
  }, [currentWork]);

  const onNext = (afterSend = false) => {
    setCurrentWorkChecked(false);
    if (currentWork?.currItemIdx !== undefined) {
      if (hasNextItemInPage(currentWork))
        setCurrentItemInPage(currentWork.currItemIdx + 1, afterSend);
      else {
        loadItemsPage(currentWork, "after");
      }
    }
  };

  const onBack = () => {
    if (currentWork && currentWork.currItemIdx !== undefined) {
      if (hasPreviousItemInPage(currentWork)) {
        setCurrentItemInPage(currentWork.currItemIdx - 1);
      } else {
        loadItemsPage(currentWork, "before");
      }
    }
  };

  const onWorkClick = (work: WorkData) => {
    setCurrentWork(work);
  };

  const onWorkClose = (work: WorkData) => {
    const workIdx = workList.findIndex(w => w.id === work.id);
    workList.splice(workIdx, 1);
    setWorkList([...workList]);
  };

  // every time we check the list of work, we update the work list
  const onWorkListClick = () => {
    const queryParams: QueryParams = { fetchPolicy: "no-cache" };
    getWorkList(queryParams)
      .then(response => {
        const updWork: WorkData[] = response?.data?.workList?.nodes || [];

        // if we already have work loaded, we keep them
        // that is because we may have some data that aren't in the new list
        // e.g. the current item in the current page of the work
        const newWorkList = updWork.map(w => {
          const existingWork = workList.find(
            existingWork => existingWork.id === w.id
          );
          return existingWork || w;
        });

        sortByName(newWorkList);
        setWorkList(newWorkList);

        // if the currentWork is gone, just assign the first one in the list
        const current = newWorkList.find(w => w.id === currentWork?.id);
        if (!current && newWorkList.length > 0) {
          setCurrentWork({ ...newWorkList[0] });
        } else if (newWorkList.length === 0) {
          setCurrentWork(undefined);
        }
      })
      .catch((err: any) => console.error(err));
  };

  const onTextChange = (value: string) => {
    setMessage(value);
  };

  const setCurrentItemInPage = (
    newCurrentItemIndex: number,
    afterSend = false
  ) => {
    if (currentWork) {
      currentWork.currItemIdx = newCurrentItemIndex;
      currentWork.currentItem = currentWork.items[newCurrentItemIndex];

      const progress = { ...currentWork.progress };
      if (afterSend) progress.processed += 1;

      setCurrentWork({ ...currentWork, progress });

      if (!showSpeedMode) {
        navigateTo(`/texts/${currentWork.currentItem.text.id}`, {
          replace: true
        });
      }
    }
  };

  const onSend = () => {
    if (currentWork && currentWork.currentItem) {
      void sendText({
        fetchPolicy: "no-cache",
        variables: { textId: currentWork.currentItem.text.id, message }
      });
      onNext(true);
    }
  };

  // "before" and "after": they are called when the user changes the current item (and page)
  // "current": it's called only when loading a project. dont change anything, just load
  const loadItemsPage = (
    work: WorkData,
    whichPage: "before" | "current" | "after"
  ) => {
    const queryParams: QueryParams = {
      fetchPolicy: "no-cache",
      variables: { workId: work.id }
    };

    setCurrentWork({
      ...work,
      paginationInfo: { ...work.paginationInfo, loading: true }
    });

    getWorkItems(queryParams)
      .then(response => {
        const { cursors, pageInfo, edges, totalCount } =
          response.data.workItems;

        const workItems = edges?.map(
          (e: WorkItemEdge) => e.node
        ) as WorkItemType[];
        work.items = formatItems(workItems || [], work.generalMessage);

        // update/refresh the pagination data
        work.paginationInfo = {
          loading: false,
          pages: cursors || [],
          page: pageInfo.page,
          pageIndex: pageInfo.pageIndex,
          hasNext: pageInfo.hasNextPage,
          hasPrevious: pageInfo.hasPreviousPage,
          total: totalCount
        };
        work.status = "loaded";

        // this is multiclick specific.
        // if we request a next page but there are no more items,
        // the response keep saying that there is still a previous page.
        // we don't want to allow going anywhere when there are no more pages.
        const noMorePages = workItems.length === 0;

        if (noMorePages) {
          work.currItemIdx = undefined;
          work.currentItem = undefined;
          setCurrentWork({
            ...work,
            progress: {
              processed: work.progress.total,
              total: work.progress.total,
              errors: work.progress.errors
            }
          });
        } else if (whichPage === "current") {
          // "current" is used only when loading the initial data
          work.currItemIdx = 0;
          work.currentItem = work.items[work.currItemIdx];
          setCurrentWork({ ...work });
          updateWorkProgress(work);

          if (work.currentItem?.contact?.phone) {
            setCommunication("message");
          }
        } else if (whichPage === "after") {
          // when after, the item will be the first in the page
          setCurrentItemInPage(0);
          updateWorkProgress(work);
        } else {
          // when before, the item will be the last in the page
          setCurrentItemInPage(work.items.length - 1);
          updateWorkProgress(work);
        }
      })
      .catch((err: any) => console.error(err));
  };

  const updateWorkProgress = (work: WorkData) => {
    const queryParams: QueryParams = {
      fetchPolicy: "no-cache",
      variables: {
        workId: work.id
      }
    };

    getProgress(queryParams)
      .then(response => {
        const progress = response.data.getWorkProgress.progress as Progress;
        setCurrentWork({ ...work, progress });
      })
      .catch((err: any) => console.error(err));
  };

  const switchViews = () => {
    if (!showSpeedMode) {
      setShowSpeedMode(true);
    } else {
      setShowSpeedMode(false);

      if (currentWork?.currentItem) {
        navigateTo(`/texts/${currentWork.currentItem.text.id}`, {
          replace: true
        });
      }
    }
  };

  // change the communication type.
  // you can't switch between communication methods directly,
  // you have to hide the current method and then choose another one.
  const toggleCommunication = (type: WorkV3Props["communicationMethod"]) => {
    setCommunication(communication ? undefined : type);
    // reset message when changing communication methods
    // messaging uses plain text and call & mail uses html
    setMessage(message || "");
  };

  const mediaUrl =
    currentWork?.imageUrl &&
    (process.env.REACT_APP_BACKEND_BASE_END_POINT || "") + currentWork.imageUrl;

  if (!currentWork) return <></>;
  // if pathname is none of these, dont show work
  if (
    ![
      LISTS_PATH,
      TEXTS_PATH,
      PROJECTS_PATH,
      USERS_PATH,
      CLIENTS_PATH,
      PHONES_PATH,
      TEAMS_PATH,
      WORK_PATH
    ].find(path => location.pathname.includes(path))
  )
    return <></>;
  if (showSpeedMode) {
    return (
      <SpeedWork
        work={currentWork}
        hasNext
        totalItems={currentWork.paginationInfo.total}
        method={"message"}
        editorText={message}
        onEditorChange={onTextChange}
        placeholders={[]}
        sentStatus={currentWorkChecked}
        onSend={onSend}
        onClose={switchViews}
        imageUrl={mediaUrl}
        mobile={windowObserver.isMobile}
      />
    );
  } else {
    return (
      <WorkV3
        workList={workList}
        currentWork={currentWork}
        communicationMethod={communication}
        communicationText={message}
        onTextChange={onTextChange}
        placeholders={[]}
        onSave={onSend}
        onWorkClick={onWorkClick}
        onWorkClose={onWorkClose}
        onWorkListClick={onWorkListClick}
        onBack={onBack}
        onNext={onNext}
        sentStatus={currentWorkChecked}
        hasPrevious={hasPreviousItemInPage(currentWork)}
        hasNext
        onCallToggle={() => toggleCommunication("call")}
        onMuteToggle={() => console.log("onMuteToggle")}
        onSilenceToggle={() => console.log("onSilenceToggle")}
        onMessageToggle={() => toggleCommunication("message")}
        onMailToggle={() => toggleCommunication("email")}
        onSkip={onNext}
        onSpeedToggle={switchViews}
        disabledCall
        disabledMail
        imageUrl={mediaUrl}
        mobile={windowObserver.isMobile}
      />
    );
  }
};

export default Work;
