/* eslint-disable react-hooks/exhaustive-deps */
import {
  Fieldset,
  NestedForm,
  NumberField,
  SearchField,
  SelectField,
  TextField,
  useWatchContext
} from "@onehq/anton";
import React, { useEffect, useMemo, useState } from "react";
import {
  ClientQueryFilterFields,
  FilterOperation,
  Provider,
  ProviderEnvKey,
  useGetClientsListLazyQuery,
  useGetProviderEnvKeyOptionsQuery
} from "../../../generated/graphql";
import { addSpacesBetweenWords, formatClientList } from "../../../utils";
import { SelectFieldOptionType } from "../../../types";
import { DEFAULT_LIMIT } from "../../../constants";
import BrandConfigurationsForm from "./BrandConfigurationsForm";
import { useParams } from "react-router-dom";

export const BrandGeneralFormOmitValues = [
  "__typename",
  "client",
  "organizationId",
  "brandConfigurationsAttributes",
  "previous"
];

type BrandConfigurationsArrayType = {
  id?: string;
  envKey?: ProviderEnvKey;
  envValue?: string;
}[];

export const providerOptions =
  Object.keys(Provider).map(item => ({
    label: addSpacesBetweenWords(item),
    value: item
  })) || [];

const BrandGeneralForm = () => {
  const [defaultClientOptions, setDefaultClientOptions] = useState<
    SelectFieldOptionType[]
  >([]);

  // is edition or creation
  const params = useParams();
  const isEdit = !!params.id;

  // queries
  const [clientsQuery] = useGetClientsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const { data: providerEnvKeyList } = useGetProviderEnvKeyOptionsQuery();
  const providerId = useWatchContext("provider");
  const brandConfigurations = useWatchContext("brandConfigurations");

  useEffect(() => {
    clientsQuery({
      variables: { limit: DEFAULT_LIMIT }
    })
      .then(response => {
        const clientsData = response.data?.clients?.nodes || [];
        setDefaultClientOptions(formatClientList(clientsData));
      })
      .catch(error => console.log(error));
  }, []);

  const ProjectCampaignsNestedForm = useMemo(() => {
    // data used to render in data grid (table)
    const brandConfigurationsArray: BrandConfigurationsArrayType =
      brandConfigurations?.map(cd => ({
        id: cd.id,
        envKey: cd.envKey,
        envValue: cd.envValue
      })) || [];

    // empty element, used for the add new row in the brand configuration data grid (table)
    brandConfigurationsArray.push({
      id: "",
      envKey: undefined,
      envValue: ""
    });

    return () => (
      <BrandConfigurationsForm
        allProviderEnvKeyOptions={providerEnvKeyList?.options.nodes || []}
        providerId={providerId}
        brandConfigurationsArray={brandConfigurationsArray}
      />
    );
  }, [providerEnvKeyList, providerId, brandConfigurations]);

  return (
    <Fieldset>
      <TextField label="Name" name="name" required />
      {/* @ts-ignore */}
      <SearchField
        label="Client"
        name="clientId"
        defaultOptions={defaultClientOptions}
        loadOptions={async (text: string) => {
          const response = await clientsQuery({
            variables: {
              filters: [
                {
                  field: ClientQueryFilterFields.Name,
                  operation: FilterOperation.Like,
                  value: text
                }
              ]
            }
          });
          return formatClientList(response.data?.clients?.nodes || []);
        }}
      />
      {/* @ts-ignore */}
      <SelectField
        label="Provider"
        name="provider"
        options={providerOptions}
        required
      />
      <NumberField label="Throughput" name="throughput" required />
      <TextField label="Code" name="code" />
      {isEdit && (
        <Fieldset>
          <NestedForm
            legend={"Configurations"}
            name="brandConfigurationsAttributes"
            component={ProjectCampaignsNestedForm}
            condensed
            addable={false}
            hasOne // will always have just one table
          />
        </Fieldset>
      )}
    </Fieldset>
  );
};

export default BrandGeneralForm;
