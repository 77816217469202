// react modules
import React from "react";

// third-party modules
import _ from "lodash";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

// app modules
import {
  PhoneType,
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation
} from "../../generated/graphql";
import {
  USER_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS,
  USERS_PATH
} from "../../constants";
import ListPage from "../../components/pages/ListPage";
import UserAnchor from "./UserAnchor";
import sections, {
  UserGeneralFormOmitValues as formOmitValues
} from "./sections";
import DataOverview from "../../components/pages/DataOverview";
import UserView from "./UserShow";

const UsersRoute = new ResourceRoute({
  name: "User",
  path: USERS_PATH,
  AnchorComponent: UserAnchor,
  IndexComponent: () => <ListPage variant="Users" />,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "User",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Texts",
      path: "texts",
      baseRoute: "User",
      IndexComponent: ({ id }: { id: string }) => (
        <UserView id={id} activeLinkId={VIEW_LINKS.TEXTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Projects",
      path: "projects",
      baseRoute: "User",
      IndexComponent: ({ id }: { id: string }) => (
        <UserView id={id} activeLinkId={VIEW_LINKS.PROJECTS} />
      )
    })
  ],
  formProps: {
    autosave: true,
    scrollable: true,
    sections,
    // @ts-ignore
    query: [useGetUserQuery, "user"],
    // @ts-ignore
    update: [useUpdateUserMutation, "updateUser"],
    // @ts-ignore
    create: [useCreateUserMutation, "createUser"],
    // @ts-ignore
    useInitialValues: (user: any) => {
      const userValues = {
        userType: user?.currentRole.name || "",
        phones: user?.phones?.length ? user.phones : [{}],
        searchTeamId: user?.team
          ? {
              label: user.team?.name,
              value: user.team?.id
            }
          : undefined,
        searchClientId: user?.client
          ? {
              label: user.client?.name,
              value: user.client.id
            }
          : undefined,
        notificationChannel: user?.notificationChannel
      };

      return userValues;
    },
    // @ts-ignore
    normalize: (user: any) => {
      if (!user) return user;

      const isEdit = !!user.id;
      const newAttr = {
        ...user,
        clientId: user.searchClientId,
        teamId: user.searchTeamId
      };

      // load phones attributes
      newAttr.phones = user.phones
        ?.filter(phone => phone.id || phone.number)
        .map(phone => {
          const { id, description } = phone;
          const number = phone.number
            ? phone.number.replace(/[-()\s_]/g, "")
            : "";
          return {
            id: id || null,
            phoneType: PhoneType.Client,
            clientId: user.searchClientId,
            number,
            description,
            _destroy:
              !!id &&
              number?.trim() === "" &&
              (!description || description?.trim() === "")
          };
        });

      return isEdit
        ? { ..._.omit(newAttr, formOmitValues) }
        : {
            userType: newAttr.userType,
            ..._.omit(newAttr, [
              ...formOmitValues,
              "__typename",
              "passwordVerify"
            ])
          };
    }
  }
});

export { UsersRoute };
