import React from "react";

import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

// app modules
import {
  ORGANIZATIONS_PATH,
  ORGANIZATION_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS
} from "../../constants";
import sections from "./OrganizationEdit/sections";
import OrganizationEdit from "./OrganizationEdit";
import OrganizationAnchor from "./OrganizationAnchor";
import OrganizationShow from "./OrganizationShow";

const OrganizationsRoute = new ResourceRoute({
  name: "Organization",
  path: ORGANIZATIONS_PATH,
  AnchorComponent: OrganizationAnchor,
  EditComponent: { component: OrganizationEdit, anchorLinks: sections },
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.OVERVIEW} />
      )
    }),
    new NestedResourceRoute({
      name: "Text Defaults",
      path: "text_defaults",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.TEXT_DEFAULTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Domains",
      path: "domains",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.DOMAINS} />
      )
    }),
    new NestedResourceRoute({
      name: "Unreg. Campaigns",
      path: "unregistered-campaigns",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow
          id={id}
          activeLinkId={VIEW_LINKS.UNREGISTERED_CAMPAIGNS}
        />
      )
    }),
    new NestedResourceRoute({
      name: "Audit Report",
      path: "audit-report",
      baseRoute: "Organization",
      IndexComponent: ({ id }: { id: string }) => (
        <OrganizationShow id={id} activeLinkId={VIEW_LINKS.AUDIT_REPORT} />
      )
    })
  ]
});

export { OrganizationsRoute };
