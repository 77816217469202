/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { Table } from "@onehq/anton";
import { Link } from "@onehq/framework";
import { ShortenedLinkListFieldsFragment } from "../../generated/graphql";
import { BaseTableProps } from "../../types";
import { SHORTENED_LINK_PATH } from "../../constants/shortenedLinkConstants";

export type ShortenedLinkTableProps =
  BaseTableProps<ShortenedLinkListFieldsFragment>;

export const ShortenedLinkTableColumns = [
  {
    header: "Shortened Link",
    accessorKey: "domain"
  },
  {
    header: "Original Link",
    accessorKey: "originalLink"
  },
  {
    header: "Clicked",
    accessorKey: "timesClicked"
  },
  {
    header: "Start At",
    accessorKey: "startAt"
  },
  {
    header: "End At",
    accessorKey: "endAt"
  }
];

export const shortenedLinkTableDataFormatter = (
  data?: Array<ShortenedLinkListFieldsFragment>
) => [
  ...(data?.map((item: ShortenedLinkListFieldsFragment) => {
    return {
      domain: (
        <Link to={`/${SHORTENED_LINK_PATH}/${item.id}/overview`}>
          {`${item.customDomain.domain}/${
            item.shortCode || item.generatedShortCode
          }`}
        </Link>
      ),
      originalLink: item.originalLink,
      timesClicked: item.timesClicked,
      startAt: item.startAt,
      endAt: item.endAt ? item.endAt : undefined
    };
  }) || [])
];

const ShortenedLinkTable = ({
  data = [],
  ...props
}: ShortenedLinkTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={ShortenedLinkTableColumns}
      data={shortenedLinkTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default ShortenedLinkTable;
