// react modules
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party modules
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  IconNames
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  useDestroyShortenedLinkMutation,
  DestroyShortenedLinkMutation,
  useGetShortenedLinkQuery
} from "../../../generated/graphql";
import sections from "../sections";
import { SHORTENED_LINK_PATH } from "../../../constants/shortenedLinkConstants";

export interface ShortenedLinkAnchorProps {
  id: string;
  route: ResourceRoute;
}

const ShortenedLinkAnchor = ({ id }: ShortenedLinkAnchorProps) => {
  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [destroyShortenedLinkMutation] = useDestroyShortenedLinkMutation({
    onCompleted: (response: DestroyShortenedLinkMutation) => {
      if (
        Object.keys(response?.destroyShortenedLink?.errors || {}).length === 0
      ) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The Shortened Link has been deleted successfully",
            variant: "success"
          }
        });
        navigateTo(`/${SHORTENED_LINK_PATH}`);
      }
    },
    onError: err => {
      console.error(err.message); // the error if that is the case
    }
  });

  const { data, loading } = useGetShortenedLinkQuery({
    context: {
      headers: {
        isView: "true",
        resource: "ShortenedLink"
      }
    },
    variables: { id }
  });

  const shortenedLink = data?.shortenedLink;

  if (loading || !shortenedLink) return <BaseResource.Anchor skeleton />;

  const deleteShortenedLink = async () => {
    await destroyShortenedLinkMutation({ variables: { id: shortenedLink.id } });
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    }
  ] as ActionItemProps[];

  const infoCard = (
    <Card.BasicInfo
      data={[
        {
          label: "Domain",
          value: shortenedLink.customDomain.domain
        },
        {
          label: "Short Code",
          value: shortenedLink.shortCode
        },
        {
          label: "Original Link",
          value: shortenedLink.originalLink
        }
      ]}
    />
  );

  const menuAnchorProps = {
    cards: [infoCard],
    editLinks: sections,
    kebabMenuItems,
    name: `${shortenedLink.customDomain.domain}/${
      shortenedLink.shortCode || shortenedLink.generatedShortCode
    }`,
    subItem: "Shortened Link",
    onEditPath: "general",
    onViewPath: "overview",
    defaultIcon: "link" as IconNames
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this Shortened Link?"
        title="Delete Shortened Link?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showModal}
        handleClose={handleClose}
        onConfirm={deleteShortenedLink}
      />
    </>
  );
};

export default ShortenedLinkAnchor;
