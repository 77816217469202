/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AuditReport as Audit } from "../../generated/graphql";
import { Table, TableProps } from "@onehq/anton";
import { BaseTableProps } from "../../types";
import { toInteger, toNumber } from "lodash";

export type AuditTableProps = BaseTableProps<Audit>;

export const AuditTableColumns: TableProps["columns"] = [
  { header: "Provider", accessorKey: "provider" },
  { header: "Date", accessorKey: "date", dataType: "date" },
  { header: "SMS Outbound", accessorKey: "smsOutbound", dataType: "number" },
  { header: "MMS Outbound", accessorKey: "mmsOutbound", dataType: "number" },
  { header: "Inbound", accessorKey: "inbound", dataType: "number" },
  {
    header: "SMS Cost (0.035/sms)",
    accessorKey: "smsCost",
    dataType: "number"
  },
  {
    header: "MMS Cost (0.035/mms)",
    accessorKey: "mmsCost",
    dataType: "number"
  },
  {
    header: "Inbound Cost (0.02/inbound)",
    accessorKey: "inboundCost",
    dataType: "number"
  }
];

export const auditTableDataFormatter = (data?: Array<Audit>) => {
  return [
    ...(data?.map((item: Audit) => {
      return {
        provider: item.provider,
        date: new Date(item.date as string),
        smsOutbound: toInteger(item.smsOutbound),
        mmsOutbound: toInteger(item.mmsOutbound),
        inbound: toInteger(item.inbound),
        smsCost: toNumber(item.smsCost) || 0,
        mmsCost: toNumber(item.mmsCost) || 0,
        inboundCost: toNumber(item.inboundCost) || 0
      };
    }) || [])
  ];
};

const AuditTable = ({ data = [], ...props }: AuditTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={AuditTableColumns}
      data={auditTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default AuditTable;
