// react modules
import React from "react";
// third-party modules
import _ from "lodash";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";
// app modules
import {
  CampaignFieldsFragment,
  CampaignType,
  useCreateCampaignMutation,
  useGetCampaignQuery,
  useNewCampaignQuery,
  useUpdateCampaignMutation
} from "../../generated/graphql";
import ListPage from "../../components/pages/ListPage";
import sections, {
  CampaignGeneralFormOmitValues as formOmitValues
} from "./sections";
import DataOverview from "../../components/pages/DataOverview";
import {
  CAMPAIGN_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS,
  CAMPAIGNS_PATH
} from "../../constants/campaignConstants";
import CampaignAnchor from "./CampaignAnchor";
import CampaignShow from "./CampaignShow";

// Check if fields that are not clientId or brandId changed
function somethingElseChanged(campaign: any) {
  const previous = campaign.previous;

  if (previous.name !== campaign.name) return true;
  if (previous.code !== campaign.code) return true;
  if (previous.startDate !== campaign.startDate) return true;
  if (previous.endDate !== campaign.endDate) return true;
  return false;
}

const CampaignsRoute = new ResourceRoute({
  name: "Campaign",
  path: CAMPAIGNS_PATH,
  AnchorComponent: CampaignAnchor,
  IndexComponent: () => <ListPage variant="Campaigns" />,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Campaign",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Phones",
      path: "phones",
      baseRoute: "Campaign",
      IndexComponent: ({ id }: { id: string }) => (
        <CampaignShow id={id} activeLinkId={VIEW_LINKS.PHONES} />
      )
    })
  ],
  formProps: {
    autosave: true,
    scrollable: true,
    sections,
    // @ts-ignore
    hydrate: [useNewCampaignQuery, "newCampaign"],
    // @ts-ignore
    query: [useGetCampaignQuery, "campaign"],
    // @ts-ignore
    update: [useUpdateCampaignMutation, "updateCampaign"],
    // @ts-ignore
    create: [useCreateCampaignMutation, "createCampaign"],
    // @ts-ignore
    useInitialValues: (campaign?: CampaignFieldsFragment) => {
      return {
        name: campaign?.name,
        client: campaign?.client,
        clientId: campaign?.client?.id,
        brand: campaign?.brand,
        brandId: campaign?.brand?.id,
        previous: campaign
      };
    },
    // @ts-ignore
    normalize: (campaign: any) => {
      if (!campaign) return campaign;

      const { campaignType, client, clientId, brand, brandId } = campaign;
      const newAttr = { ...campaign };

      const brandChanged = brandId !== brand?.id;
      const clientChanged = clientId !== client?.id;

      // invalid cases
      // 1. campaign type changed and client become deprecated
      const invalid1 =
        (campaignType === CampaignType.Registered && !clientId) ||
        (campaignType === CampaignType.Unregistered && clientId);
      // 3. client changed and brand becomes deprecated
      const invalid2 = clientChanged && !brandChanged;

      if ((invalid1 || invalid2) && !somethingElseChanged(campaign)) {
        return { ...newAttr, _omitSubmit: true };
      } else return { ..._.omit(newAttr, formOmitValues) };
    }
  }
});

export { CampaignsRoute };
