// react modules
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party modules
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  DotColor,
  Heading,
  IconNames,
  Table
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  Link,
  REMOVE,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";
import { theme } from "@onehq/style";
import styled from "styled-components";

// app modules
import {
  ClientFieldsFragment,
  ClientStatus,
  DestroyClientMutation,
  refetchGetClientQuery,
  ToggleFavoriteMutation,
  UpdateClientMutation,
  UpdateClientMutationVariables,
  useDestroyClientMutation,
  useGetClientQuery,
  useGetClientStatusOptionsQuery,
  useIsFavoriteQuery,
  useToggleFavoriteMutation,
  useUpdateClientMutation,
  useUpdateClientStatusMutation
} from "../../../generated/graphql";
import {
  addSpacesBetweenWords,
  clientStatus,
  formatDate,
  formatPhone,
  getOptionFieldsAndColor
} from "../../../utils";
import sections from "../sections";
import { CLIENTS_PATH } from "../../../constants";

const dropdownHeading = "UPDATE STATUS";
const COLORS: DotColor[] = ["iris50", "gold60", "pea60"];

export interface ClientAnchorProps {
  id: string;
  route: ResourceRoute;
}

const StyledHeading = styled(Heading)`
  margin: ${theme.space.spacing12} 0;
`;

const ClientAnchor = ({ id }: ClientAnchorProps) => {
  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const { data, loading } = useGetClientQuery({
    context: {
      headers: {
        isView: "true",
        resource: "Client"
      }
    },
    variables: { id }
  });
  const client = data?.client;

  const { data: statusOptionsData, loading: statusOptionsloading } =
    useGetClientStatusOptionsQuery();

  const clientStatusOptions = getOptionFieldsAndColor(
    statusOptionsData,
    COLORS
  );

  const { data: isFavoriteData } = useIsFavoriteQuery({
    fetchPolicy: "no-cache",
    variables: { clientId: id }
  });

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    setIsFavorite(isFavoriteData?.isFavorite || false);
  }, [isFavoriteData]);

  const [toggleFavorite] = useToggleFavoriteMutation({
    onCompleted: (response: ToggleFavoriteMutation) => {
      setIsFavorite(response.toggleFavorite?.isFavorite || false);
    }
  });

  const [updateClientStatus] = useUpdateClientStatusMutation({
    refetchQueries: [refetchGetClientQuery({ id })]
  });

  const [destroyClientMutation] = useDestroyClientMutation({
    onCompleted: (response: DestroyClientMutation) => {
      if (Object.keys(response?.destroyClient?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The client has been deleted successfully",
            variant: "success"
          }
        });
        navigateTo("/clients");
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
      alert({
        type: ADD,
        payload: {
          title: "Failed",
          message: err.message,
          variant: "error"
        }
      });
    }
  });

  const [updateClientMutation] = useUpdateClientMutation({
    onCompleted: (response: UpdateClientMutation) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (Object.keys(response.updateClient?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "Avatar changed",
            message: "The avatar has been changed successfully",
            variant: "success"
          }
        });
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  const changeAvatar = (image: string) => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Uploading file...",
        variant: "progress",
        indeterminate: true
      }
    });
    const variables: UpdateClientMutationVariables = {
      id,
      attributes: {}
    };
    if (image) {
      variables.attributes = {
        mediaInput: image
      };
    } else {
      variables.attributes = {
        mediaDestroy: true
      };
    }
    updateClientMutation({ variables }).then(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _ => {
        alert({
          type: REMOVE,
          payload: {
            id: idForAlert,
            title: "",
            variant: "success"
          }
        });

        // TO DO: uncomment when forms are added
        // if (response.data?.updateClient?.resource?.mediaUrl && values) {
        //   values.mediaUrl = response.data.updateClient.resource.mediaUrl;
        // }
      },
      error => {
        alert({
          type: REMOVE,
          payload: {
            id: idForAlert,
            title: "",
            variant: "success"
          }
        });
        console.log("err: ", error);
      }
    );
  };

  if (statusOptionsloading || !clientStatusOptions || !client)
    return <BaseResource.Anchor skeleton />;

  const deleteClient = async () => {
    await destroyClientMutation({ variables: { id: client.id } });
  };

  const changeStatus = async (
    currentClient: ClientFieldsFragment,
    newStatus: ClientStatus
  ) => {
    const newClient = {
      id: currentClient.id,
      clientType: currentClient.clientType,
      clientStatus: newStatus
    };
    await updateClientStatus({
      variables: { id: newClient.id, attributes: newClient }
    }).then(
      () => {
        console.log("success");
      },
      err => {
        console.log(err);
      }
    );
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    }
  ] as ActionItemProps[];

  const infoCard = (
    <Card.BasicInfo
      data={[
        { label: "Name", value: client.name },
        { label: "Type", value: addSpacesBetweenWords(client.clientType) },
        {
          label: "Agency",
          value: client.agency ? (
            <Link to={`/${CLIENTS_PATH}/${client.agency.id}/overview`}>
              {client.agency.name}
            </Link>
          ) : (
            "--"
          )
        },
        { label: "Created At", value: formatDate(client.createdAt) },
        { label: "Note", value: client.note },
        {
          label: "Opt-in",
          value: client.slug ? (
            <Link to={`/${client.slug}/opt-in`}>{client.slug}</Link>
          ) : (
            "--"
          )
        }
      ]}
    />
  );

  const clientNumbersCard = (
    <Card.Flex>
      <StyledHeading>Internal Phone Numbers</StyledHeading>
      <Table
        skeleton={loading}
        variant="minimal"
        name="numbers"
        notSortable
        hideHeader
        columns={[
          {
            header: "Number",
            accessorKey: "number",
            cell: ({ getValue }) => formatPhone(getValue() as string)
          },
          {
            header: "Description",
            accessorKey: "description"
          }
        ]}
        data={data?.client?.clientNumbers || []}
      />
    </Card.Flex>
  );

  const numbersByProvider = {};
  data?.client?.providerNumbers.forEach(pn => {
    numbersByProvider[pn.phoneType] = numbersByProvider[pn.phoneType]
      ? ++numbersByProvider[pn.phoneType]
      : 1;
  });

  const providerNumbersCard = (
    <Card.Flex>
      <StyledHeading>Numbers by provider</StyledHeading>
      <Table
        variant="minimal"
        name="numbers"
        notSortable
        hideHeader
        columns={[
          {
            header: "label",
            accessorKey: "label"
          },
          {
            header: "value",
            accessorKey: "value",
            dataType: "number"
          }
        ]}
        data={Object.keys(numbersByProvider).map(key => ({
          label: key,
          value: numbersByProvider[key]
        }))}
      />
    </Card.Flex>
  );

  const menuAnchorProps = {
    editLinks: sections,
    cards: [infoCard, clientNumbersCard, providerNumbersCard],
    name: client.name,
    subItem: "Client",
    onEditPath: "general",
    onViewPath: "overview",
    statusOptions: clientStatusOptions,
    currentStatus: client.clientStatus,
    type: client.clientStatus,
    kebabMenuItems,
    onAvatarSubmit: changeAvatar,
    changeStatus: (status: ClientStatus) => changeStatus(client, status),
    dropdown: {
      status: clientStatus(client.clientStatus),
      heading: dropdownHeading,
      links: clientStatusOptions,
      onClick: (status: ClientStatus) => changeStatus(client, status)
    },
    onFavoriteToggle: () => toggleFavorite({ variables: { clientId: id } }),
    favorite: isFavorite,
    defaultIcon: "briefcase" as IconNames
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this client?"
        title="Delete Client?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showModal}
        handleClose={handleClose}
        onConfirm={deleteClient}
      />
    </>
  );
};

export default ClientAnchor;
