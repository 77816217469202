// third-party modules
import { FormSection } from "@onehq/framework";

import ClientGeneralForm from "./ClientGeneralForm";
import ClientNumbersForm from "./ClientNumbersForm";
import ClientOptIn from "./ClientOptIn";

export * from "./ClientGeneralForm";
export default [
  new FormSection("General", ClientGeneralForm),
  new FormSection("Opt in", ClientOptIn),
  new FormSection("Phone Numbers", ClientNumbersForm)
];
