export const TEAMS_PATH = "teams";

export const TEAM_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  USERS: "Users",
  PROJECTS: "Projects",
  TEXTS: "Texts"
};

export const TEAM_MENU_ANCHOR_EDIT_LINKS = {
  GENERAL: "General"
};

export const TEAM_VIEW_LINKS = [
  {
    id: TEAM_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: TEAM_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: TEAM_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: TEAM_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    text: TEAM_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    name: TEAM_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    path: "/projects"
  },
  {
    id: TEAM_MENU_ANCHOR_VIEW_LINKS.USERS,
    text: TEAM_MENU_ANCHOR_VIEW_LINKS.USERS,
    name: TEAM_MENU_ANCHOR_VIEW_LINKS.USERS,
    path: "/users"
  },
  {
    id: TEAM_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    text: TEAM_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    name: TEAM_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    path: "/texts"
  }
];

// not used yet
export const TEAM_EDIT_LINKS = [
  {
    id: TEAM_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    text: TEAM_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    name: TEAM_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    path: "general"
  }
];
