export const CLIENTS_PATH = "clients";

export const CLIENT_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  PROJECTS: "Projects",
  AGENCIES: "Agencies"
};

export const CLIENT_MENU_ANCHOR_EDIT_LINKS = {
  GENERAL: "General"
};

export const CLIENT_VIEW_LINKS = [
  {
    id: CLIENT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: CLIENT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: CLIENT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: CLIENT_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    text: CLIENT_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    name: CLIENT_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    path: "/projects"
  },
  {
    id: CLIENT_MENU_ANCHOR_VIEW_LINKS.AGENCIES,
    text: CLIENT_MENU_ANCHOR_VIEW_LINKS.AGENCIES,
    name: CLIENT_MENU_ANCHOR_VIEW_LINKS.AGENCIES,
    path: "/agencies"
  }
];

// not used yet
export const CLIENT_EDIT_LINKS = [
  {
    id: CLIENT_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    text: CLIENT_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    name: CLIENT_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    path: "general"
  }
];
