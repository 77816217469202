export const ORGANIZATIONS_PATH = "organization";

export const ORGANIZATION_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  TEXT_DEFAULTS: "Text Defaults",
  DOMAINS: "Domains",
  UNREGISTERED_CAMPAIGNS: "Unregistered Campaigns",
  AUDIT_REPORT: "Audit Report"
};

export const ORGANIZATION_MENU_ANCHOR_EDIT_LINKS = {
  GENERAL: "General",
  TEXT_DEFAULTS: "Text Defaults"
};
