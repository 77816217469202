export const LISTS_PATH = "lists";

export const LIST_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  PROJECTS: "Projects",
  ROWS: "Rows"
};
export const LIST_MENU_ANCHOR_EDIT_LINKS = {
  GENERAL: "General"
};

export const LIST_VIEW_LINKS = [
  {
    id: LIST_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: LIST_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: LIST_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: LIST_MENU_ANCHOR_VIEW_LINKS.ROWS,
    text: LIST_MENU_ANCHOR_VIEW_LINKS.ROWS,
    name: LIST_MENU_ANCHOR_VIEW_LINKS.ROWS,
    path: "/rows"
  },
  {
    id: LIST_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    text: LIST_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    name: LIST_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    path: "/projects"
  }
];
// not used yet
export const LIST_EDIT_LINKS = [
  {
    id: LIST_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    text: LIST_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    name: LIST_MENU_ANCHOR_EDIT_LINKS.GENERAL,
    path: "/"
  }
];
