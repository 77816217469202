/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { Table } from "@onehq/anton";
import { Link } from "@onehq/framework";
import { CampaignListFieldsFragment } from "../../generated/graphql";
import { BaseTableProps } from "../../types";

export const CampaignTableColumns = [
  {
    header: "Name",
    accessorKey: "name"
  },
  {
    header: "Brand",
    accessorKey: "brandName",
    disableSortBy: true
  },
  {
    header: "Provider",
    accessorKey: "providerName",
    disableSortBy: true
  },
  {
    header: "Code",
    accessorKey: "code",
    disableSortBy: true
  },
  {
    header: "Type",
    accessorKey: "type",
    disableSortBy: true
  }
];

export const campaignTableDataFormatter = (
  data?: Array<CampaignListFieldsFragment>
) => [
  ...(data?.map((item: CampaignListFieldsFragment) => {
    const { id, name, brand, code, campaignType, organizationId } = item;
    return {
      name: organizationId ? (
        <Link to={`/campaigns/${id}/overview`}>{name}</Link>
      ) : (
        name
      ),
      brandName: brand.name,
      providerName: brand.provider,
      code,
      type: campaignType
    };
  }) || [])
];

export type CampaignTableProps = BaseTableProps<CampaignListFieldsFragment>;

const CampaignTable = ({ data = [], ...props }: CampaignTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={CampaignTableColumns}
      data={campaignTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default CampaignTable;
