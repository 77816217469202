/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ProjectTexterFieldsFragment } from "../../generated/graphql";
import { CellDataType, Table } from "@onehq/anton";
import { formatDate, formatNumber } from "../../utils/helperFunctions";
import { Link } from "@onehq/framework";
import { BaseTableProps } from "../../types";

export type ProjectTexterTableProps =
  BaseTableProps<ProjectTexterFieldsFragment>;

export const ProjectTexterTableColumns = [
  {
    header: "Name",
    accessorKey: "name",
    disableSortBy: true
  },
  {
    header: "Quota",
    dataType: "number" as CellDataType,
    accessorKey: "quotum"
  },
  {
    header: "Created At",
    accessorKey: "createdAt"
  }
];

export const projectTexterDataFormatter = (
  data?: Array<ProjectTexterFieldsFragment>
) => [
  ...(data?.map((item: ProjectTexterFieldsFragment) => ({
    name: <Link to={`/users/${item.userId}`}>{item.user.name}</Link>,
    quotum: formatNumber(item.quota),
    createdAt: formatDate(item.createdAt)
  })) || [])
];

const ProjectTexterTable = ({
  data = [],
  ...props
}: ProjectTexterTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={ProjectTexterTableColumns}
      data={projectTexterDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default ProjectTexterTable;
