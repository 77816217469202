import React from "react";

import { LinkId } from "@onehq/anton";

import { CAMPAIGN_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS } from "../../../constants/campaignConstants";
import DataOverview from "../../../components/pages/DataOverview";
import {
  FilterOperation,
  PhoneQueryFilterFields,
  useGetPhonesListQuery
} from "../../../generated/graphql";
import { List } from "@onehq/framework";
import { phoneTableDataFormatter } from "../../../components/Phone/PhoneTable";

const campaignPhonesColumns = [
  {
    id: "number",
    header: "Phone Number",
    accessorKey: "number"
  },
  {
    id: "phoneStatus",
    header: "Status",
    accessorKey: "phoneStatus"
  }
];

interface CampaignProps {
  id: string;
  activeLinkId: LinkId;
}

const CampaignShow = ({ id, ...props }: CampaignProps) => {
  const { data, loading, refetch } = useGetPhonesListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: PhoneQueryFilterFields.CampaignId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  switch (props.activeLinkId) {
    case VIEW_LINKS.OVERVIEW:
      return <DataOverview />;
    case VIEW_LINKS.PHONES:
      return (
        <List
          accessor="phones"
          columns={campaignPhonesColumns}
          data={data}
          dataFormat={phoneTableDataFormatter}
          loading={loading}
          refetchQuery={refetch}
        />
      );
    default:
      return <></>;
  }
};

export default CampaignShow;
