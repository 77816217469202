// react modules
import React from "react";

// third-party modules
import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

// app modules
import {
  GetProjectsListQuery,
  GetUsersListQuery,
  GetTextsListQuery,
  useGetUsersListLazyQuery,
  useGetProjectTeamsListQuery,
  useGetProjectsListLazyQuery,
  useGetTextsListLazyQuery,
  UserQueryFilterFields,
  ProjectTeamQueryFilterFields,
  ProjectQueryFilterFields,
  TextQueryFilterFields,
  FilterOperation
} from "../../../generated/graphql";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";
import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../../../components/Text/TextTable";
import {
  UserTableColumns,
  userTableDataFormatter
} from "../../../components/User/UserTable";
import { TEAM_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS } from "../../../constants/teamConstants";
import DataOverview from "../../../components/pages/DataOverview";

interface TeamShowProps {
  id: string;
  activeLinkId: LinkId;
  setTotalPages?: Function;
}

const TeamShow = ({ id, ...props }: TeamShowProps) => {
  const [getUsersQuery, { data: usersData, loading: loadingUsers }] =
    useGetUsersListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        filters: [
          {
            field: UserQueryFilterFields.TeamId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      onCompleted: (response: GetUsersListQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props.setTotalPages && props.setTotalPages(response.users.totalCount);
      }
    });

  // getting project teams related to this team
  const { data, loading, error } = useGetProjectTeamsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: ProjectTeamQueryFilterFields.TeamId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });

  const hasValues = !!data && !loading && !error && !!data.projectTeams?.nodes;
  const projectsOnTeam = hasValues
    ? data.projectTeams?.nodes?.map(pt => pt?.projectId as string)
    : [];

  // getting projects to show, using the projectTexters list to make the filters
  const [getProjectsQuery, { data: projectsData, loading: loadingProjects }] =
    useGetProjectsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        // @ts-ignore
        filters: hasValues
          ? [
              {
                field: ProjectQueryFilterFields.Id,
                operation: FilterOperation.In,
                arrayValues: projectsOnTeam
              }
            ]
          : []
      },
      onCompleted: (response: GetProjectsListQuery) => {
        props.setTotalPages &&
          props.setTotalPages(response.projects.totalCount);
      }
    });

  // getting texts to show, using the text list to make the filters
  const [getTextsQuery, { data: textsData, loading: loadingTexts }] =
    useGetTextsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: {
        // @ts-ignore
        filters: hasValues
          ? [
              {
                field: TextQueryFilterFields.ProjectId,
                operation: FilterOperation.In,
                arrayValues: projectsOnTeam
              }
            ]
          : []
      },
      onCompleted: (response: GetTextsListQuery) => {
        props.setTotalPages && props.setTotalPages(response.texts.totalCount);
      }
    });

  const formattedTexts = useTextTableDataFormatter(
    // @ts-ignore
    textsData?.texts?.nodes || []
  );

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === VIEW_LINKS.OVERVIEW)
    props.setTotalPages(null);

  return (
    <>
      {props.activeLinkId === VIEW_LINKS.OVERVIEW && <DataOverview />}
      {props.activeLinkId === VIEW_LINKS.PROJECTS && (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectsData}
          dataFormat={projectTableDataFormatter}
          loading={loadingProjects}
          refetchQuery={getProjectsQuery}
        />
      )}
      {props.activeLinkId === VIEW_LINKS.TEXTS && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={getTextsQuery}
        />
      )}
      {props.activeLinkId === VIEW_LINKS.USERS && (
        <List
          accessor="users"
          columns={UserTableColumns}
          data={usersData}
          dataFormat={userTableDataFormatter}
          loading={loadingUsers}
          refetchQuery={getUsersQuery}
        />
      )}
    </>
  );
};

export default TeamShow;
