/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useEffect, useMemo, useState } from "react";

// third-party modules
import {
  Fieldset,
  NestedForm,
  useReset,
  useWatchContext,
  useGetValue,
  MultiSwitchField
} from "@onehq/anton";

// app modules
import ProjectPhonesFields, { AreaCodeData } from "./ProjectPhonesFields";
import {
  addSpacesBetweenWords,
  formatCampaignOption,
  formatPhoneOption
} from "../../../utils";
import {
  Campaign,
  CampaignQueryFilterFields,
  Phone as PhoneData,
  PhoneType,
  FilterOperation,
  PhoneQueryFilterFields,
  PhoneStatus,
  ProjectAreaCodesQuery,
  ProjectStatus,
  Provider,
  SendingType,
  useGetCampaignsListLazyQuery,
  useGetPhonesListLazyQuery,
  useProjectAreaCodesQuery,
  CampaignType,
  useGetCampaignsListQuery
} from "../../../generated/graphql";
import FloatingForm from "../../../components/Form/FloatingForm";
import { campaignTypeOptions } from "../../campaigns/sections";

const pendingPhoneStatuses = [
  PhoneStatus.Pending,
  PhoneStatus.AwaitingApiVerify,
  PhoneStatus.AwaitingMmsVerify,
  PhoneStatus.AwaitingSmsVerify
].map(s => `PhoneStatus::::${addSpacesBetweenWords(s)}`);

export const sendingTypeOptions = Object.values(SendingType)
  .reverse()
  .map(s => ({
    label: addSpacesBetweenWords(SendingType[s]),
    value: s
  }));

const PREFIX = "projectPhonesAttributes";

const Providers = Object.keys(Provider);
const PhoneTypes = Object.keys(PhoneType).filter(p => Providers.includes(p));

export type Phone = Partial<
  Pick<PhoneData, "id" | "number" | "description" | "campaignId">
>;

const ProjectCampaignsForm = () => {
  // the values for the table
  const [areaCodeData, setAreaCodeData] = useState<AreaCodeData[]>([]);
  const [retrievedAreaCodes, setRetrievedAreaCodes] = useState<
    NonNullable<ProjectAreaCodesQuery["projectAreaCodes"]["edges"]>
  >([]);
  const [currentList, setCurrentList] = useState<string>();
  const [campaignsWithOrg, setCampaignsWithOrg] = useState<Campaign[]>([]);
  // all the phones for all providers in the project
  const [allPhones, setAllPhones] = useState<Phone[]>([]);
  const [pendingPhones, setPendingPhones] = useState<any[]>([]);

  const getValue = useGetValue();

  // floating form variables
  // show dialog to add campaigns
  const [showDialog, setShowDialog] = useState(false);
  // gets the text used to do the search campaign and set it to the dialog name
  const [searchText, setSearchText] = useState<string | null>(null);
  // gets the current input name from where add was called (i.e. projectTextersAttributes.campaignId-0)
  const [addToInputName, setAddToInputName] = useState<string | null>(null);
  // whenever floating form creates an element, this values is set to true
  const [submitForm, setSubmitForm] = useState(false);

  // hooks
  const projectId = useWatchContext("id");
  const listId = useWatchContext("list")?.id;
  const clientId = useWatchContext("clientId")?.value;
  const projectPhones = useWatchContext("projectPhones");
  const projectPhonesAttributes = useWatchContext(PREFIX);
  const projectStatus = useWatchContext("projectStatus");
  const campaignType = useWatchContext("campaignType");
  const reset = useReset();

  const isComplete = useMemo(() => {
    return projectStatus === ProjectStatus.Complete;
  }, [projectStatus]);

  // queries
  // a paginated list of all area codes for the list of the project
  const projectAreaCodes = useProjectAreaCodesQuery({
    fetchPolicy: "network-only",
    variables: { projectId, first: 20 }
  });
  const [getPhones] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [getCampaigns] = useGetCampaignsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const { data: campaignsWithoutOrgData } = useGetCampaignsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: CampaignQueryFilterFields.OrganizationId,
          operation: FilterOperation.With,
          value: "false"
        }
      ]
    }
  });
  const [getPendingPhones] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const allCampaigns = useMemo(() => {
    if (campaignType === CampaignType.Registered) return [...campaignsWithOrg];
    else {
      const campaignsWithoutOrg =
        (campaignsWithoutOrgData?.campaigns.nodes?.filter(c => !!c) ||
          []) as Campaign[];
      return [...campaignsWithoutOrg, ...campaignsWithOrg];
    }
  }, [campaignsWithOrg, campaignsWithoutOrgData, campaignType]);

  useEffect(() => {
    getPendingPhones({
      variables: {
        filters: [
          {
            field: PhoneQueryFilterFields.PhoneStatusId,
            operation: FilterOperation.In,
            arrayValues: pendingPhoneStatuses
          }
        ]
      }
    })
      .then(response => {
        const phones = response.data?.phones.nodes;
        setPendingPhones(phones || []);
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    // new edges for the same list OR new edges of another list
    const newData = projectAreaCodes.data?.projectAreaCodes;
    const newRows = newData?.edges || [];
    const newRowsIsPage1 = !newData?.pageInfo.hasPreviousPage;
    // if the old rows are already set, we set the new pages in the nested form;
    // so this setter is used only once*, the first time.
    if (newRowsIsPage1) setRetrievedAreaCodes(newRows);
  }, [projectAreaCodes]);

  const defaultAreaCodeData = useMemo(() => {
    // this query give us the skeleton of the table
    // just the area codes & times used doesn't have the campaigns nor phones
    return retrievedAreaCodes.map(n => ({
      areaCode: n?.node?.areaCode || "",
      timesUsed: n?.node?.timesUsed || 0,
      cursor: n?.cursor || ""
    }));
  }, [retrievedAreaCodes]);

  useEffect(() => {
    if (listId !== currentList) setCurrentList(listId as string | undefined);
  }, [listId]);

  useEffect(() => {
    void projectAreaCodes.refetch({ after: "" });
  }, [currentList]);

  // the campaigns can be only from the client or with client=null
  useEffect(() => {
    // if registered, retrieves all registered campaigns of the org
    // if unregistered, retrieves all unregistered campaigns of the org only
    // we fetch unregistered campaigns with no org with another query
    getCampaigns({
      variables: {
        filters: [
          {
            field: CampaignQueryFilterFields.CampaignTypeId,
            operation: FilterOperation.Equal,
            value: `CampaignType::::${campaignType}`
          },
          {
            field: CampaignQueryFilterFields.ClientId,
            operation:
              campaignType === CampaignType.Registered
                ? FilterOperation.Equal
                : FilterOperation.With,
            value: campaignType === CampaignType.Registered ? clientId : "false"
          }
        ]
      }
    })
      .then(response => {
        setCampaignsWithOrg(
          (response.data?.campaigns?.nodes || []) as Campaign[]
        );
      })
      .catch(errors => console.log(errors));
  }, [clientId, campaignType]);

  useEffect(() => {
    // all phones that can be of use in the table
    getPhones({
      variables: {
        filters: [
          ...(campaignType === CampaignType.Registered
            ? [
                {
                  field: PhoneQueryFilterFields.PhoneTypeId,
                  operation: FilterOperation.In,
                  arrayValues: PhoneTypes.map(p => `PhoneType::::${p}`)
                }
              ]
            : [
                {
                  field: PhoneQueryFilterFields.CampaignId,
                  operation: FilterOperation.In,
                  arrayValues: allCampaigns.map(c => c.id)
                }
              ]),
          {
            field: PhoneQueryFilterFields.PhoneStatusId,
            operation: FilterOperation.Equal,
            value: `PhoneStatus::::${PhoneStatus.Active}`
          }
        ]
      }
    })
      .then(response => {
        const phones = response.data?.phones?.nodes || [];
        setAllPhones([
          ...phones.map(n => ({
            id: n?.id,
            number: n?.number,
            campaignId: n?.campaignId
          }))
        ]);
      })
      .catch(errors => console.log(errors));
  }, [allCampaigns, campaignType]);

  // when we get the area codes & the project campaigns
  // we have to merge them, each area code has a row and could have a pair campaign/phone
  useEffect(() => {
    // fix an edgy case: change project resets project phones
    const keys: {} = { ...(projectPhonesAttributes || {}) };
    delete keys["id"];
    const isRegistered = campaignType === CampaignType.Registered;
    const fieldsNumber = isRegistered ? 4 : 3;
    const oldRowsNumber = Object.keys(keys).length / fieldsNumber;
    const newRowsNumber = defaultAreaCodeData.length;
    // no change
    if (oldRowsNumber === newRowsNumber) return;

    // all the created project phones, each will have a row to match
    const formatRows = defaultAreaCodeData.map(row => {
      const projectPhone = projectPhones.find(
        projectPhone => projectPhone.areaCode === parseInt(row.areaCode, 10)
      );
      const opt = projectPhone && formatPhoneOption(projectPhone.phone);
      if (projectPhone)
        opt.value = {
          id: projectPhone.phone.id,
          campaignId: projectPhone.campaign.id
        };
      // we just merge the row data & the pc data
      const rowData: any = {
        cursor: row.cursor,
        areaCode: row.areaCode,
        timesUsed: row.timesUsed,
        phoneData: projectPhone ? opt : undefined
      };
      if (isRegistered) {
        rowData.campaignId = projectPhone
          ? formatCampaignOption(projectPhone.campaign)
          : undefined;
      }
      return rowData;
    });
    // this table format is for the fields inside the table
    // they don't use the table's data, but the formbuilder data
    // each row add a `-{index}` to the end of the column field name
    const tableFormat = formatRows.reduce((previous, current, index) => {
      previous[`areaCode-${index}`] = current.areaCode || null;
      previous[`timesUsed-${index}`] = current.timesUsed || null;
      if (isRegistered) {
        previous[`campaignId-${index}`] = current.campaignId || null;
      }
      previous[`phoneData-${index}`] = current.phoneData || null;
      return previous;
    }, {});

    // this sets the values for the table,
    // tbh, this is only useful with customcells,
    // since all form values are invinsible for the table
    // and the table values are invinsible for the fields & form
    const tableValues = formatRows.map(r => {
      const value = { ...r, phoneData: r.phoneData?.value };
      if (isRegistered) value.campaignId = r.campaignId?.value;
      return value;
    });
    setAreaCodeData(tableValues);
    // reset the table data for the formbuilder to show the last changes
    reset(PREFIX, tableFormat, {
      absolutePath: true,
      keepStateOptions: {
        keepIsSubmitted: true,
        keepIsValid: true,
        keepDirty: true,
        keepTouched: true,
        keepSubmitCount: true
      }
    });
  }, [
    defaultAreaCodeData,
    projectPhones,
    projectPhonesAttributes,
    campaignType
  ]);

  // when a campaign is created, fetch new campaignId to the table data
  useEffect(() => {
    if (submitForm && addToInputName) {
      // gets the new campaign id
      const newElementId = getValue(addToInputName)?.value || "";

      // get the index based on the input name
      const arrayIndex = addToInputName.split("-").slice(-1)[0];

      // sets the new element id to the table data
      const updatedAreaCodeData = [...areaCodeData];
      updatedAreaCodeData[arrayIndex] = {
        ...updatedAreaCodeData[arrayIndex],
        campaignId: newElementId
      };
      setAreaCodeData(updatedAreaCodeData);

      // reset floating form variables
      setSubmitForm(false);
      setAddToInputName(null);
    }
  }, [addToInputName, submitForm]);

  // we use a nested form because that was the only way that
  // the usesetfieldvalue & usereset taked our changes
  const ProjectPhonesForm = useMemo(() => {
    return () => (
      <ProjectPhonesFields
        clientId={clientId}
        areaCodeData={areaCodeData}
        setRetrievedAreaCodes={setRetrievedAreaCodes}
        setAreaCodeData={setAreaCodeData}
        campaignType={campaignType}
        isCompleted={isComplete}
        setShowDialog={setShowDialog}
        setSearchText={setSearchText}
        setAddToInputName={setAddToInputName}
        prefix={PREFIX}
        refetchQuery={projectAreaCodes.refetch}
        allPhones={allPhones}
        allCampaigns={allCampaigns}
        pendingPhones={pendingPhones}
      />
    );
  }, [
    clientId,
    areaCodeData,
    campaignType,
    allCampaigns,
    allPhones,
    pendingPhones
  ]);

  return (
    <>
      <Fieldset>
        <MultiSwitchField
          label="Campaign Type"
          name="campaignType"
          tabs={campaignTypeOptions}
          disabled={isComplete}
        />
        <MultiSwitchField
          label="Sending Type"
          name="sendingType"
          tabs={sendingTypeOptions}
          disabled={isComplete}
          span={12}
        />
      </Fieldset>
      <Fieldset>
        {/* @ts-ignore */}
        <NestedForm
          name={PREFIX}
          component={ProjectPhonesForm}
          addable={false}
          hasOne // will always have just one table
        />
        {/* Dialog to create campaigns */}
        <FloatingForm
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
            setSearchText(null);
          }}
          variant={"Campaign"}
          defaultValues={{
            name: searchText
          }}
          attachNewValueToInput
          customFieldName={addToInputName || ""}
          setSubmitForm={setSubmitForm}
        />
      </Fieldset>
    </>
  );
};

export default ProjectCampaignsForm;
