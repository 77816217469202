import React from "react";
import _ from "lodash";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

import TeamAnchor from "./TeamAnchor";
import ListPage from "../../components/pages/ListPage";
import {
  TEAMS_PATH,
  TEAM_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS
} from "../../constants";
import sections from "./sections";
import {
  useCreateTeamMutation,
  useGetTeamQuery,
  useNewProjectTeamQuery,
  useUpdateTeamMutation
} from "../../generated/graphql";

import {
  teamFormOmitValues as formOmitValues,
  TeamFieldswithOrganizationId
} from "./sections/TeamGeneralForm";
import DataOverview from "../../components/pages/DataOverview";
import TeamShow from "./TeamShow";

const TeamsRoute = new ResourceRoute({
  name: "Team",
  path: TEAMS_PATH,
  IndexComponent: () => <ListPage variant="Teams" />,
  AnchorComponent: TeamAnchor,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Team",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Projects",
      path: "texts",
      baseRoute: "Team",
      IndexComponent: ({ id }: { id: string }) => (
        <TeamShow id={id} activeLinkId={VIEW_LINKS.PROJECTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Texts",
      path: "projects",
      baseRoute: "Team",
      IndexComponent: ({ id }: { id: string }) => (
        <TeamShow id={id} activeLinkId={VIEW_LINKS.TEXTS} />
      )
    }),
    new NestedResourceRoute({
      name: "Users",
      path: "users",
      baseRoute: "Team",
      IndexComponent: ({ id }: { id: string }) => (
        <TeamShow id={id} activeLinkId={VIEW_LINKS.USERS} />
      )
    })
  ],
  formProps: {
    scrollable: true,
    autosave: true,
    sections,
    // @ts-ignore
    hydrate: [useNewProjectTeamQuery, "newProjectTeam"],
    // @ts-ignore
    query: [useGetTeamQuery, "team"],
    // @ts-ignore
    update: [useUpdateTeamMutation, "updateTeam"],
    // @ts-ignore
    create: [useCreateTeamMutation, "createTeam"],
    // @ts-ignore
    normalize: (team: TeamFieldswithOrganizationId) => {
      if (!team) return team;
      return {
        // this should be auto set by the backend, meanwhile, we hardcode set it on creation (is replaced in edition)
        ..._.omit(team, formOmitValues)
      };
    }
  }
});

export { TeamsRoute };
