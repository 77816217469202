import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { QueryResult } from "@apollo/client";
import { Pagination, Table, useWatchContext } from "@onehq/anton";
import {
  FilterOperation,
  GetRowsListQuery,
  Row,
  RowQueryFilterFields,
  useGetRowsListQuery
} from "../../generated/graphql";

const Root = styled.div`
  width: 100%;
`;

const PaginationContainer = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: center;
`;

const ListSummary = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<any>([]);

  const listId = useWatchContext("listId")?.value;

  const listRows: QueryResult<GetRowsListQuery> = useGetRowsListQuery({
    variables: {
      filters: [
        {
          field: RowQueryFilterFields.ListId,
          operation: FilterOperation.Equal,
          value: listId
        }
      ],
      first: 20
    }
  });

  useEffect(() => {
    setRows((listRows.data?.rows.nodes as Row[]) || []);
    setPages(listRows?.data?.rows.cursors || []);
  }, [listRows]);

  useEffect(() => {
    if (page > 0) {
      void listRows.refetch({ after: pages?.[page - 1] || "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const tableColumns = [
    { header: "Column One", accessorKey: "ColumnOne" },
    { header: "Number", accessorKey: "Number" }
  ];

  const tableData = rows.map(r => {
    return { ColumnOne: r.columnOne, Number: r.toPhoneNumber };
  });

  return (
    <Root>
      <Table columns={tableColumns} data={tableData} notSortable />
      <PaginationContainer>
        <Pagination
          currentPage={page}
          onPageClick={page => setPage(page)}
          totalPages={pages?.length || 0}
        />
      </PaginationContainer>
    </Root>
  );
};

export default ListSummary;
